@font-face {
  font-family: 'MuseoSansCyrl-300';
  src: url('/i/fonts/MuseoSansCyrl-300.eot');
  src: local('MuseoSansCyrl-300'),
       url('/i/fonts/MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'),
       url('/i/fonts/MuseoSansCyrl-300.woff2') format('woff2'),
       url('/i/fonts/MuseoSansCyrl-300.woff') format('woff'),
       url('/i/fonts/MuseoSansCyrl-300.ttf') format('truetype'),
       url('/i/fonts/MuseoSansCyrl-300.svg#MuseoSansCyrl-300') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSansCyrl-500';
  src: url('/i/fonts/MuseoSansCyrl-500.eot');
  src: local('MuseoSansCyrl-500'),
       url('/i/fonts/MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype'),
       url('/i/fonts/MuseoSansCyrl-500.woff2') format('woff2'),
       url('/i/fonts/MuseoSansCyrl-500.woff') format('woff'),
       url('/i/fonts/MuseoSansCyrl-500.ttf') format('truetype'),
       url('/i/fonts/MuseoSansCyrl-500.svg#MuseoSansCyrl-500') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSansCyrl-700';
  src: url('/i/fonts/MuseoSansCyrl-700.eot');
  src: local('MuseoSansCyrl-700'),
       url('/i/fonts/MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'),
       url('/i/fonts/MuseoSansCyrl-700.woff2') format('woff2'),
       url('/i/fonts/MuseoSansCyrl-700.woff') format('woff'),
       url('/i/fonts/MuseoSansCyrl-700.ttf') format('truetype'),
       url('/i/fonts/MuseoSansCyrl-700.svg#MuseoSansCyrl-700') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSansCyrl-900';
  src: url('/i/fonts/MuseoSansCyrl-900.eot');
  src: local('MuseoSansCyrl-900'),
       url('/i/fonts/MuseoSansCyrl-900.eot?#iefix') format('embedded-opentype'),
       url('/i/fonts/MuseoSansCyrl-900.woff2') format('woff2'),
       url('/i/fonts/MuseoSansCyrl-900.woff') format('woff'),
       url('/i/fonts/MuseoSansCyrl-900.ttf') format('truetype'),
       url('/i/fonts/MuseoSansCyrl-900.svg#MuseoSansCyrl-900') format('svg');
  font-weight: normal;
  font-style: normal;
}