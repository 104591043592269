/*
  Обозначения:
  _a (all) - применяет свойства к элементу и его дочерним элементам
  _h (hover) - применяет свойства к элементу при получении им фокуса (например, наведение указателя мыши)
  _i (important) - применяет свойства с повышенным приоритетом
*/
html, body {
  width: 100%;
}

body {
  background: #fff;
  overflow-x: hidden;
}

span.required {
  font-family: 'MuseoSansCyrl-700', 'Helvetica', 'Tahoma', 'Arial', sans-serif;
  font-weight: 700;
  color: #e81d28;
}

.bgcgray, .bgcgray_h:hover { background-color: #eef0f2; }
.bgcgray_i, .bgcgray_h_i:hover { background-color: #eef0f2 !important; }
.bgcmgray, .bgcmgray_h:hover { background-color: #e1e7ec; }
.bgcmgray_i, .bgcmgray_h_i:hover { background-color: #e1e7ec !important; }
.bgcdgray, .bgcdgray_h:hover { background-color: #262526; }
.bgcdgray_i, .bgcdgray_h_i:hover { background-color: #262526 !important; }

.cgray, .cgray_h:hover { color: #eef0f2; }
.cgray_i, .cgray_h_i:hover { color: #eef0f2 !important; }
.cmgray, .cmgray_h:hover { color: #e1e7ec; }
.cmgray_i, .cmgray_h_i:hover { color: #e1e7ec !important; }
.cdgray, .cdgray_h:hover { color: #262526; }
.cdgray_i, .cdgray_h_i:hover { color: #262526 !important; }

.brcgray, .brcgray_h:hover { border-color: #eef0f2; }
.brcgray_i, .brcgray_h_i:hover { border-color: #eef0f2 !important; }
.brcmgray, .brcmgray_h:hover { border-color: #e1e7ec; }
.brcmgray_i, .brcmgray_h_i:hover { border-color: #e1e7ec !important; }
.brcdgray, .brcdgray_h:hover { border-color: #262526; }
.brcdgray_i, .brcdgray_h_i:hover { border-color: #262526 !important; }



/* Components */
p { margin-bottom: 22px; }
p.before-ul { margin-bottom: 12px; }
p + p { margin-top: -8px; }
.subheader + p, .subsubheader + p { margin-top: -6px; }


.link {
  color: #e81d28;
  cursor: pointer;
}
.link,
.link.invert:hover {
  text-decoration: underline;
}
  .link:hover,
  .link.invert {
    text-decoration: none;
  }


.form-block-container {
  background: #fff;
  padding: 30px;
}
  .form-popup-container .form-block-container {
    min-width: 400px;
    max-width: 700px;
  }
    .form-popup-container.fancybox-confirm .form-block-container {
      min-width: unset;
    }

  .form-block-container .subsubheader {
    padding-top: 0px;
  }

  .form-wrapper {
    margin: -25px 0px 0px -25px;
  }
    .form-wrapper #form-success-message {
      padding: 25px 0px 0px 25px;
    }

    .form {
      position: relative;
    }
      .form-row {
        line-height: 1.25;
        padding: 25px 0px 0px 25px;
      }
        .form-label {
          margin: 0px 0px 8px;
        }
          .form-row > .form-label {
            font-family: 'MuseoSansCyrl-700', 'Helvetica', 'Tahoma', 'Arial', sans-serif;
            font-weight: 700;
          }

          .form-row-checkbox .form-label,
          .form-row-checkboxlist .form-field > div > label > span > span + span,
          .form-row-radiolist .form-field > div > label > span > span + span {
            font-size: 14px;
            display: block;
            margin: 2px 0px 2px 10px;
            cursor: pointer;
          }
            .form-row-checkbox .form-field.disabled + .form-label,
            .form-row-checkboxlist .form-field > div > input.disabled + label > span > span + span,
            .form-row-radiolist .form-field > div > input.disabled + label > span > span + span {
              color: #bbb;
              cursor: default;
            }

            .form-row-checkbox .form-label label {
              cursor: pointer;
            }


        .input-container {
          background: #e1e1e1;
          border-radius: 10px;
          padding: 15px 20px;
          position: relative;
          z-index: 1;
        }
          .input-container input,
          .input-container textarea,
          .input-container select {
            background: transparent;
            font-size: 1em;
            font-weight: 400;
            line-height: 1.25;
            border: 0px;
            padding: 0px;
            margin: 0px;
          }
          .input-container input:not([type='radio']):not([type='checkbox']),
          .input-container textarea,
          .input-container select {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
          }
            .input-container input::-webkit-input-placeholder { color: #262526; opacity: 0.7; }
            .input-container input:-moz-placeholder { color: #262526; opacity: 0.7; }
            .input-container input::-moz-placeholder { color: #262526; opacity: 0.7; }
            .input-container input:-ms-input-placeholder { color: #262526; opacity: 0.7; }
            .input-container input::-ms-input-placeholder { color: #262526; opacity: 0.7; }
            .input-container input::placeholder { color: #262526; opacity: 0.7; }


        .form-error {
          position: relative;
        }
          .errorMessage {
            color: #fff;
            background: #e81d28;
            border-radius: 5px;
            font-size: 13px;
            line-height: 1.25;
            padding: 3px 6px;
            margin: 2px 0px 0px;
          }
            .form-row-checkbox .errorMessage {
              margin-top: 0px;
            }


          .form-row-checkboxlist .form-field > div + div,
          .form-row-radiolist .form-field > div + div {
            margin: 8px 0px 0px;
          }
            .form-row-checkboxlist .form-field > div > label,
            .form-row-radiolist .form-field > div > label {
              display: inline-block;
            }

            .form-row-checkbox .form-field-wrapper,
            .form-row-checkboxlist .form-field > div > label > span,
            .form-row-radiolist .form-field > div > label > span {
              display: inline-flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
            }
              .form-row-checkbox .form-field,
              .form-row-checkboxlist .form-field > div,
              .form-row-radiolist .form-field > div {
                align-self: flex-start;
                position: relative;
              }
                .form-row-checkbox .form-field input,
                .form-row-checkboxlist .form-field > div > input,
                .form-row-radiolist .form-field > div > input {
                  border: none;
                  width: 0px;
                  height: 0px;
                  padding: 0px;
                  margin: auto;
                  top: 0px;
                  left: 2px;
                  bottom: 0px;
                  position: absolute;
                  opacity: 0;
                  z-index: -1;
                }

                .form-row-checkbox .form-field > span,
                .form-row-checkboxlist .form-field > div > label > span > span:first-child,
                .form-row-radiolist .form-field > div > label > span > span:first-child {
                  content: '';
                  display: block;
                  color: transparent;
                  background: #dfdfdf;
                  border-radius: 5px;
                  font-size: 0px;
                  line-height: 0px;
                  width: 24px;
                  min-width: 24px;
                  height: 24px;
                  position: relative;
                  cursor: pointer;
                }
                .form-row-radiolist .form-field > div > label > span > span:first-child {
                  border-radius: 50%;
                  width: 22px;
                  min-width: 22px;
                  height: 22px;
                  margin: 1px;
                }
                  .form-row-checkbox .form-field > input.disabled + span,
                  .form-row-checkboxlist .form-field > div > input.disabled + label > span > span:first-child,
                  .form-row-radiolist .form-field > div > input.disabled + label > span > span:first-child {
                    background: #f1f1f1;
                    border-color: transparent !important;
                    cursor: default;
                  }

                  .form-row-checkbox .form-field > span label {
                    content: '';
                    color: transparent;
                    display: block;
                    font-size: 0px;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    margin: auto;
                    cursor: pointer;
                  }
                    .form-row-checkbox .form-field > span label:after,
                    .form-row-checkboxlist .form-field > div > label > span > span:first-child:after,
                    .form-row-radiolist .form-field > div > label > span > span:first-child:after {
                      content: '';
                      display: block;
                      position: absolute;
                      margin: auto;
                      visibility: hidden;
                      opacity: 0;
                      transition: visibility 0s ease 0.3s, opacity 0.3s ease 0s;
                    }
                    .form-row-checkbox .form-field > span label:after,
                    .form-row-checkboxlist .form-field > div > label > span > span:first-child:after {
                      background: url('/i/icon-checkbox--black.svg') no-repeat 0% 50%/contain transparent;
                      top: 0px;
                      left: 5px;
                      right: 5px;
                      bottom: 0px;
                    }
                      .form-row-checkbox .form-field input.disabled + span label:after,
                      .form-row-checkboxlist .form-field > div > input.disabled + label > span > span:first-child:after {
                        background-image: url('/i/icon-checkbox--gray.svg');
                      }

                    .form-row-radiolist .form-field > div > label > span > span:first-child:after {
                      background: #262526;
                      border-radius: 50%;
                      top: 6px;
                      left: 6px;
                      right: 6px;
                      bottom: 6px;
                    }
                      .form-row-checkbox .form-field input:checked + span label:after,
                      .form-row-checkboxlist .form-field > div > input:checked + label > span > span:first-child:after,
                      .form-row-radiolist .form-field > div > input:checked + label > span > span:first-child:after {
                        visibility: unset;
                        opacity: 1;
                        transition: visibility 0s ease 0s, opacity 0.3s ease 0s;
                      }


        .form-row-name-captcha .form-field,
        .form-row-name-captcha .captcha-container {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
        }
          .form-row-name-captcha .captcha-container {
            align-items: stretch;
            background: #e1e1e1;
            border-radius: 10px 0px 0px 10px;
          }
            .form-row-name-captcha .captcha-container img {
              padding: 2px 0px 1px;
            }

            .form-row-name-captcha .captcha-container .refresh-button.button-style {
              border-radius: 0px;
              min-width: unset;
              max-width: unset;
              padding: 0px;
            }
              .form-row-name-captcha .captcha-container .refresh-button.button-style span {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: stretch;
                font-size: 0;
                height: 100%;
                min-width: 50px;
              }
                .form-row-name-captcha .captcha-container .refresh-button.button-style span:after {
                  content: '';
                  display: block;
                  background: url('/i/refresh-button-icon.svg') no-repeat 50% 50%/18px auto transparent;
                  line-height: 0;
                  width: 100%;
                }

          .form-row-name-captcha .captcha-container + div {
            flex-grow: 1;
          }
            .form-row-name-captcha .captcha-container + div .input-container {
              border-radius: 0px 10px 10px 0px;
            }


      .form-buttons {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: flex-start;
      }
        .form-button {
          padding: 25px 0px 0px 25px;
        }



.button-style {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #e81d28;
  border: none;
  border-radius: 999px;
  line-height: 1.25;
  text-align: center;
  text-decoration: none;
  padding: 19px 31px;
  cursor: pointer;
  user-select: none;
  position: relative;
}
  .button-style:hover {
    background: #c51821;
  }



ul.ul, ol.ol {
  margin: -6px 0px 35px;
}
ol.ol { counter-reset: ol-list-counter; }
  ul.ul ul.ul, ol.ol ol.ol {
    padding-left: 12px;
    margin-top: 0px;
    margin-bottom: 12px;
  }
  ul.ul li, ol.ol li {
    padding-top: 10px;
    padding-bottom: 0px;
    position: relative;
  }
    ul.ul li {
      padding-left: 20px;
    }
      ul.ul li:before {
        content: '●';
        color: transparent;
        display: inline-block;
        background: url('/i/ul-list-style-image.svg') no-repeat 50% 50%/contain transparent;
        width: 8px;
        margin: 0px 10px 0px -18px;
      }

      ol.ol li:before {
        content: counters(ol-list-counter, '.') '. ';
        counter-increment: ol-list-counter;
        font-weight: 600;
      }



.price-separator {
  font-size: inherit;
  width: 0px;
  height: 0px;
  padding: 0em 0.09375em;
}



.unwrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
  .unwrapper > div {
    width: 100vw;
    min-width: 100vw;
  }

.wrapper {
  width: 100%;
  max-width: 1300px;
  padding: 0px 10px;
  margin: 0px auto;
  position: relative;
}



.text-container {
  padding: 0px 0px 45px;
}
/* Components end */



/* Structure */
  .structure {
    overflow: hidden;
    transform-origin: center top;
  }


  /* Header */
  .spam {
    display: none;
  }


  header {
    position: relative;
    z-index: 5;
  }
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      color: #142433;
      padding: 16px 0px;
    }
      .logo {
        align-self: center;
        line-height: 0;
        padding-right: 20px;
      }

      .header__center {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: space-between;
        border-left: 2px solid #ebebeb;
      }
        .header__center-first {
          font-size: 14px;
          max-width: 285px;
          margin-left: 19px;
        }
          .header__center-first,
          .header__center-first a {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          }
          .header__center-first img {
            width: auto;
            margin-right: 8px;
          }

        .header__center-second {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
        }
          .header__center-second div:first-child {
            color: #24bb17;
            font-size: 12px;
            text-align: right;
            margin: 7px 0px 6px;
            position: relative;
          }
            .header__center-second div:first-child:after {
              content: '';
              background: #24bb17;
              border-radius: 50%;
              width: 7px;
              height: 7px;
              top: 3px;
              left: -12px;
              position: absolute;
            }

          .header-btn {
            font-size: 11px;
            text-align: center;
            padding: 10px 26px;
          }


        .header__phones {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          font-family: 'MuseoSansCyrl-700', 'Helvetica', 'Tahoma', 'Arial', sans-serif;
          font-weight: 700;
          font-size: 20px;
          text-align: right;
          border-left: 2px solid #ebebeb;
          padding-left: 21px;
          margin-left: 25px;
        }
          .header__phones > a {
            text-decoration: none;
          }
          .header__phones > a + a {
            margin-top: 6px;
          }

          .header__phones .call-mob {
            display: none;
          }


      .header-menu-wrapper {
      }
        .header-menu-button-wrapper,
        .header-menu-checkbox,
        .header-menu .dropmarker {
          display: none;
        }

        .header-menu {
        }
          .header-menu ul {
            display: block;
          }
          .header-menu > ul {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: stretch;
          }
            .header-menu ul > li {
              flex: 0 1 auto;
              position: relative;
            }
            .header-menu > ul > li {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: stretch;
            }
                .header-menu ul > li > div > div {
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: stretch;
                  position: relative;
                  padding: 15px 0 0;
                }
                .header-menu > ul > li > div > div {
                  padding: 0px;
                }
                  .header-menu ul div > span {
                    cursor: default;
                  }
                  .header-menu ul div > a {
                    cursor: pointer;
                  }
                    .header-menu ul div > a:hover {
                      text-decoration: underline;
                    }
                    .header-menu li.with-active > div > div div > span,
                    .header-menu li.with-active > div > div div > a,
                    .header-menu li.active > div > div div > a {
                      font-family: 'MuseoSansCyrl-700', 'Helvetica', 'Tahoma', 'Arial', sans-serif;
                      font-weight: 700;
                    }
                    .header-menu li.active > div > div div > a {
                      color: #e81d28;
                      text-decoration: none;
                    }

                  .header-menu > ul > li > div > div > div > a,
                  .header-menu > ul > li > div > div > div > span {
                    display: block;
                    font-size: 18px;
                    text-transform: uppercase;
                    padding: 10px 0px;
                  }


          .header-menu > ul ul {
            position: absolute;
            visibility: hidden;
            opacity: 0;
          }
          .header-menu > ul > li > div > ul {
            background: #fff;
            box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.09);
            top: 100%;
            left: 0px;
            min-width: 100%;
            padding: 10px 25px 30px;
            white-space: nowrap;

            transition: visibility 0s ease 0.2s, opacity 0.2s ease 0s;
          }
            .header-menu > ul > li > div:hover > ul {
              visibility: unset;
              opacity: 1;
              transition: visibility 0s ease 0s, opacity 0.2s ease 0s;
            }
  /* Header end */



  /* Content block */
  .content {
    border-top: 2px solid #ebebeb;
    position: relative;
    z-index: 3;
  }
    .subheader,
    .subsubheader {
      font-family: 'MuseoSansCyrl-300', 'Helvetica', 'Tahoma', 'Arial', sans-serif;
      font-weight: 700;
      text-align: center;
      max-width: 748px;
      margin: 0px auto;
      position: relative;
      z-index: 2;
    }
      .subheader span,
      .subsubheader span {
        color: #e81d28;
      }

      .subheader {
        font-size: 36px;
        padding: 50px 0px 60px;
      }

      .subsubheader {
        font-size: 24px;
        padding: 28px 0px 30px;
      }
        .subheader + .subsubheader {
          padding-top: 0px;
        }



    .first-block {
      position: relative;
      padding: 0px 0px 41px;
      margin: -2px 0px 30px;
      width: 100%;
    }
      .first__block-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        top: 0;
        left: 0;
        object-fit: cover;
      }
        .first__block-bg img {
          width: 100%;
          height: 100%;
          max-height: 100%;
          object-fit: cover;
        }


      .max-bg {
        position: absolute;
        bottom: -4px;
        right: 110px;
      }
        .max-bg-mob {
          display: none;
        }

      .glass-bg {
        position: absolute;
        bottom: -200px;
        right: 0;
      }
        .mob-glass {
          display: none;
        }

      .sign1 {
        position: absolute;
        right: 0;
      }


      .first-block_slogan {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: #e81d28;
        font-family: 'MuseoSansCyrl-700', 'Helvetica', 'Tahoma', 'Arial', sans-serif;
        font-weight: 700;
        font-size: 24px;
        padding-top: 81px;
      }
        .first-block_slogan:before {
          content: '';
          width: 49px;
          height: 2px;
          background: #e81d28;
          margin-right: 25px;
        }


      .first-block_title {
        color: #fff;
        font-family: 'MuseoSansCyrl-300', 'Helvetica', 'Tahoma', 'Arial', sans-serif;
        font-weight: 700;
        font-size: 40px;
        max-width: 748px;
        margin: 21px 0px 31px;
      }


      .first-block_border {
        width: 80%;
        position: relative;
        margin-top: 68px;
        padding-top: 37px;
      }
        .border-bg {
          position: absolute;
          width: 100%;
          top: 0;
          left: -31px;
          height: calc(100% + 37px);
          border: 1px dashed #fff;
          border-radius: 5px;
          z-index: -1;
        }

        .first-block_border-title {
          color: #fff;
          font-family: 'MuseoSansCyrl-900', 'Helvetica', 'Tahoma', 'Arial', sans-serif;
          font-weight: 700;
          font-size: 24px;
        }

        .first-block_border-subtitle {
          color: #fff;
          font-size: 18px;
          margin-bottom: 24px;
          margin-top: 6px;
          position: relative;
        }


      .todo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        border-radius: 5px;
        padding: 25px 25px 22px 25px;
        margin-top: 67px;
        max-width: 850px;
        left: -31px;
        position: relative;
      }
        .todo-next {
          width: 29px;
          min-width: 29px;
          margin: 0px 20px;
          z-index: 2;
        }

        .todo__text {
          flex: 1 1 auto;
          font-size: 14px;
          z-index: 2;
        }



    .grey-bg > div {
      background: #eef0f2;
      padding: 0px 0px 15px;
      position: relative;
    }
      .grey-bg > div:before {
        content: '';
        display: block;
        background: url('/i/grey-bg.png') no-repeat 0% 0%;
        top: 50px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        position: absolute;
      }



    .vitrine {
      padding: 0px 0px 45px;
      position: relative;
      overflow: hidden;
    }
      .vitrine > div {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-content: flex-start;
        align-items: stretch;
        margin: -1px -1px 0px -1px;
      }
        .vitrine-item {
          width: 33.33333333%;
          position: relative;
        }
          .vitrine-item > div {
            border: solid #f0f0f0;
            border-width: 1px 1px 0px 1px;
            height: 100%;
            padding: 38px 20px 45px;
            margin-left: -1px;
          }
            .vitrine-item-image {
              font-size: 0px;
              max-width: 270px;
              margin: auto;
              position: relative;
              order: 1;
              z-index: 1;
            }
              .vitrine-item-image:before {
                content: '';
                display: block;
                background: #eef0f2;
                height: 0px;
                padding: 70% 0% 0%;
              }

              .vitrine-item-image > a,
              .vitrine-item-image > span {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: stretch;
                background: no-repeat 50% 50%/cover;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                z-index: 1;
              }
              .vitrine-item-image > a {
                cursor: pointer;
              }

              .vitrine-item-image > a[data-lazy-src],
              .vitrine-item-image > span[data-lazy-src] {
                opacity: 0;
              }
                .vitrine-item-image > a[data-lazy-src].unveil-loaded,
                .vitrine-item-image > span[data-lazy-src].unveil-loaded {
                  opacity: 1;
                  transition: opacity 0.3s ease 0s;
                }


            .vitrine-item-text {
              text-align: center;
              height: 100%;
              padding: 25px 9% 0px;
              position: relative;
              order: 2;
              z-index: 2;
            }
              .vitrine-item-name a,
              .vitrine-item-name span {
                font-family: 'MuseoSansCyrl-500', 'Helvetica', 'Tahoma', 'Arial', sans-serif;
                font-weight: 400;
                padding: 0px;
              }

              .vitrine-item-name a {
                color: #000;
              }
                .vitrine-item-image:hover + .vitrine-item-name a,
                .vitrine-item-image:hover + .vitrine-item-text .vitrine-item-name a {
                  text-decoration: none;
                }



    .advantages .vitrine-item-image {
      max-width: 190px;
    }
      .advantages .vitrine-item-image:before {
        border-radius: 50%;
        padding: 100% 0% 0%;
      }

      .advantages .vitrine-item-image:after {
        content: '';
        display: block;
        background: url('/i/icon-tick.svg') no-repeat 50% 50%/contain;
        width: 36px;
        height: 36px;
        left: calc(50% - 17px);
        bottom: -20px;
        position: absolute;
        z-index: 2;
      }

      .advantages .vitrine-item-image > a,
      .advantages .vitrine-item-image > span {
        border-radius: 50%;
      }


    .advantages .vitrine-item-text {
      padding-top: 28px;
    }



    .gallery {
      padding: 0px 2px;
    }
      .gallery > div {
        padding: 0px 0px 45px;
        position: relative;
      }

      .gallery > div:first-child {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-content: flex-start;
        align-items: flex-start;
        margin: -25px 0px 0px -20px;
      }
        .gallery > div:first-child > div {
          padding: 25px 0px 0px 20px;
          position: relative;
        }
          .gallery .width-1 {
            width: 100%;
          }
          .gallery .width-2 {
            width: 50%;
          }
          .gallery .width-3 {
            width: 33.33333333%;
          }
          .gallery .width-4 {
            width: 25%;
          }
          .gallery .width-5 {
            width: 20%;
            max-width: 239px;
          }
          .gallery .width-6 {
            width: 16.66666666%;
          }

          .gallery > div:first-child > div > div:first-child {
            background: #eef0f2;
            line-height: 0;
            position: relative;
          }
            .gallery img {
              width: 100%;
              opacity: 0;
              transition: opacity 0.2s ease 0.1s;
            }
              .gallery img.unveil-loaded {
                padding-top: 0px !important;
                opacity: 1;
              }


          .gallery > div:first-child > div > div + div {
            text-align: center;
            padding: 12px 0px 0px;
          }


        .gallery .gallery-notice {
          text-align: center;
          margin: -33px 0px 0px;
        }



    .incut-image,
    .incut {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px 0px 45px;
    }
      .incut-image:after {
        content: '';
        flex-shrink: 0;
        background: url('/i/call-block.png') no-repeat 50% 50%/contain;
        width: 0px;
        min-width: 245px;
        height: 128px;
      }

      .incut-image > div,
      .incut > div {
        flex-grow: 1;
        align-self: stretch;
        background: #2c3843;
        border-radius: 5px;
        padding: 40px 70px 40px 55px;
      }



    .tab-list {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: center;
      padding: 0px 0px 30px;
      margin: -15px 0px 0px -20px;
      position: relative;
      z-index: 1;
    }
      .tab-list > div {
        margin: 15px 0px 0px 20px;
      }
        .tab-list a {
          border-bottom: 1px dashed #000;
        }
          .tab-list a:hover,
          .tab-list a.active {
            border-color: transparent;
          }

          .tab-list a.active {
            font-family: 'MuseoSansCyrl-700', 'Helvetica', 'Tahoma', 'Arial', sans-serif;
            font-weight: 700;
            color: #e81d28;
          }



    .price-table {
      padding: 0px 0px 45px;
    }
      .price-table-items {
        position: relative;
      }
        .price-table-items > div {
          display: none;
        }
          .price-table-items > div.active {
            display: unset;
          }

          .price-table-items > div > div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: stretch;
            border: solid #ebebeb;
            border-width: 0px 0px 2px;
          }
            .price-name,
            .price-price {
              padding: 10px 8px;
              flex-basis: 100%;
              flex-shrink: 4;
            }
            .price-name {
              padding-left: 30px;
            }
            .price-price {
              text-align: center;
              flex-shrink: 7;
            }

          .price-table-head {
            font-family: 'MuseoSansCyrl-700', 'Helvetica', 'Tahoma', 'Arial', sans-serif;
            font-weight: 700;
            font-size: 20px;
            background: #eef0f2;
            border: none !important;
          }
            .price-table-head .price-name,
            .price-table-head .price-price {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: flex-end;
            }
            .price-table-head .price-price {
              justify-content: center;
            }

          .price-table-subheader {
            font-family: 'MuseoSansCyrl-500', 'Helvetica', 'Tahoma', 'Arial', sans-serif;
            font-weight: 700;
          }
  /* Content block end */



  /* Contact page */
  .contact-info-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: flex-start;
    font-size: 18px;
    padding: 0px 0px 45px;
    margin-top: -20px;
  }
    .contact-info-container > div {
      flex: 1 1 auto;
      padding: 20px 50px 0px 0px;
    }
      .contact-info-label {
        font-family: 'MuseoSansCyrl-700', 'Helvetica', 'Tahoma', 'Arial', sans-serif;
        font-weight: 700;
        font-size: 20px;
        padding: 0px 0px 4px;
      }

      .contact-phones > div > div + div,
      .contact-emails > div > div + div {
        padding-top: 2px;
      }
        .contact-phones div > a {
          font-family: 'MuseoSansCyrl-700', 'Helvetica', 'Tahoma', 'Arial', sans-serif;
          font-weight: 700;
          font-size: 22px;
          white-space: nowrap;
        }

      .contact-emails a {
        font-family: 'MuseoSansCyrl-500', 'Helvetica', 'Tahoma', 'Arial', sans-serif;
        font-weight: 700;
        line-height: 1.1;
        text-decoration: underline;
        white-space: nowrap;
        cursor: pointer;
      }
        .contact-emails a:hover {
          text-decoration: none;
        }
  /* Contact page end */



  /* Footer */
  footer {
    color: #fff;
    background: #142433;
    position: relative;
    z-index: 1;
  }
    footer .header {
      color: #fff;
    }
      footer .info {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        padding: 0px 20px;
        border-left: 2px solid #2c3843;
      }

      footer .header__center,
      footer .header__phones {
        border-color: #2c3843;
      }


    .footer-bottom {
      border-top: 2px solid #2c3843;
      padding: 0px 0px 16px;
    }
      .footer-bottom > div {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0px 0px 0px -25px;
      }
        .footer-bottom > div > div {
          text-align: center;
          padding: 16px 0px 0px 25px;
        }

        .footer-bottom .link {
          color: #fff;
        }
  /* Footer end */
/* Structure end */