@media screen and (max-width: 1300px) {
  .max-bg {
    right: calc((100vw - 1300px) * 0.7);
  }

  .first-block_border {
    margin-left: 31px;
  }

  .todo {
    left: 0px;
  }
}

/* landscape tablet - lt */
@media screen and (max-width: 1023px) {
  /* Border */
  /* ====== */
  /* Border width */
  .lt_brw0 { border-width: 0px; }
  .lt_brtw0 { border-top-width: 0px; }
  .lt_brlw0 { border-left-width: 0px; }
  .lt_brrw0 { border-right-width: 0px; }
  .lt_brbw0 { border-bottom-width: 0px; }
  .lt_brw1 { border-width: 1px; }
  .lt_brtw1 { border-top-width: 1px; }
  .lt_brlw1 { border-left-width: 1px; }
  .lt_brrw1 { border-right-width: 1px; }
  .lt_brbw1 { border-bottom-width: 1px; }

  /* Border style */
  .lt_brstn { border-style: none; }
  .lt_brsts { border-style: solid; }
  .lt_brstd { border-style: dashed; }


  /* Display */
  /* ======= */
  .lt_dn { display: none; }
  .lt_dn_i { display: none !important; }
  .lt_db { display: block; }
  .lt_di { display: inline; }
  .lt_dib { display: inline-block; }
  .lt_dt { display: table; }
  .lt_dtr { display: table-row; }
  .lt_dtc { display: table-cell; }
  .lt_du { display: unset; }

  /* Flex */
  .lt_dfdr { display: flex; flex-direction: row; }
  .lt_dfdrr { display: flex; flex-direction: row-reverse; }
  .lt_dfdc { display: flex; flex-direction: column; }
  .lt_dfdcr { display: flex; flex-direction: column-reverse; }
  .lt_difdr { display: inline-flex; flex-direction: row; }
  .lt_difdrr { display: inline-flex; flex-direction: row-reverse; }
  .lt_difdc { display: inline-flex; flex-direction: column; }
  .lt_difdcr { display: inline-flex; flex-direction: column-reverse; }
  .lt_fwn { flex-wrap: nowrap; }
  .lt_fww { flex-wrap: wrap; }
  .lt_fwwr { flex-wrap: wrap-reverse; }
  .lt_fjcs { justify-content: flex-start; }
  .lt_fjce { justify-content: flex-end; }
  .lt_fjcc { justify-content: center; }
  .lt_fjcsa { justify-content: space-around; }
  .lt_fjcsb { justify-content: space-between; }
  .lt_fais { align-items: flex-start; }
  .lt_faie { align-items: flex-end; }
  .lt_faic { align-items: center; }
  .lt_faib { align-items: baseline; }
  .lt_faist { align-items: stretch; }
  .lt_facs { align-content: flex-start; }
  .lt_face { align-content: flex-end; }
  .lt_facc { align-content: center; }
  .lt_facsa { align-content: space-around; }
  .lt_facsb { align-content: space-between; }
  .lt_facst { align-content: stretch; }
  .lt_fass { align-self: flex-start; }
  .lt_fase { align-self: flex-end; }
  .lt_fasc { align-self: center; }
  .lt_fasb { align-self: baseline; }
  .lt_fasst { align-self: stretch; }
  .lt_fg0 { flex-grow: 0; }
  .lt_fg1 { flex-grow: 1; }
  .lt_fsh0 { flex-shrink: 0; }
  .lt_fsh1 { flex-shrink: 1; }


  /* Height */
  /* ====== */
  .lt_h0 { height: 0; }
  .lt_h100p, .lt_hfull { height: 100%; }
  .lt_hauto { height: auto; }


  /* Width */
  /* ===== */
  .lt_w0 { width: 0; }
  .lt_w1 { width: 1px; }
  .lt_w5p { width: 5%; }
  .lt_w7p { width: 7%; }
  .lt_w10p { width: 10%; }
  .lt_w12p { width: 12.5%; }
  .lt_w15p { width: 15%; }
  .lt_w20p { width: 20%; }
  .lt_w25p { width: 25%; }
  .lt_w33p { width: 33.33333333%; }
  .lt_w35p { width: 35%; }
  .lt_w40p { width: 40%; }
  .lt_w50p { width: 50%; }
  .lt_w60p { width: 60%; }
  .lt_w65p { width: 65%; }
  .lt_w66p { width: 66.66666666%; }
  .lt_w70p { width: 70%; }
  .lt_w75p { width: 75%; }
  .lt_w80p { width: 80%; }
  .lt_w85p { width: 85%; }
  .lt_w88p { width: 87.5%; }
  .lt_w90p { width: 90%; }
  .lt_w93p { width: 93%; }
  .lt_w95p { width: 95%; }
  .lt_w100p, .lt_wfull { width: 100%; }
  .lt_wauto { width: auto; }


  /* Margins */
  /* ======= */
  .lt_m0auto, .lt_m0a { margin: 0 auto; }
  .lt_m0auto_i, .lt_m0a_i { margin: 0 auto !important; }
  .lt_mauto0, .lt_ma0 { margin: auto 0; }
  .lt_mauto0_i, .lt_ma0_i { margin: auto 0 !important; }
  .lt_mauto, .lt_ma { margin: auto; }
  .lt_mauto_i, .lt_ma_i { margin: auto !important; }
  .lt_mtauto, .lt_mta { margin-top: auto; }
  .lt_mtauto_i, .lt_mta_i { margin-top: auto !important; }
  .lt_mlauto, .lt_mla { margin-left: auto; }
  .lt_mlauto_i, .lt_mla_i { margin-left: auto !important; }
  .lt_mrauto, .lt_mra { margin-right: auto; }
  .lt_mrauto_i, .lt_mra_i { margin-right: auto !important; }
  .lt_mbauto, .lt_mba { margin-bottom: auto; }
  .lt_mbauto_i, .lt_mba_i { margin-bottom: auto !important; }
  .lt_m0 { margin: 0; }
  .lt_m0_i { margin: 0 !important; }
  .lt_mt0 { margin-top: 0; }
  .lt_mt0_i { margin-top: 0 !important; }
  .lt_ml0 { margin-left: 0; }
  .lt_ml0_i { margin-left: 0 !important; }
  .lt_mr0 { margin-right: 0; }
  .lt_mr0_i { margin-right: 0 !important; }
  .lt_mb0 { margin-bottom: 0; }
  .lt_mb0_i { margin-bottom: 0 !important; }
  .lt_m1 { margin: 1px; }
  .lt_mt1 { margin-top: 1px; }
  .lt_ml1 { margin-left: 1px; }
  .lt_mr1 { margin-right: 1px; }
  .lt_mb1 { margin-bottom: 1px; }
  .lt_m2 { margin: 2px; }
  .lt_mt2 { margin-top: 2px; }
  .lt_ml2 { margin-left: 2px; }
  .lt_mr2 { margin-right: 2px; }
  .lt_mb2 { margin-bottom: 2px; }
  .lt_m3 { margin: 3px; }
  .lt_mt3 { margin-top: 3px; }
  .lt_ml3 { margin-left: 3px; }
  .lt_mr3 { margin-right: 3px; }
  .lt_mb3 { margin-bottom: 3px; }
  .lt_m4 { margin: 4px; }
  .lt_mt4 { margin-top: 4px; }
  .lt_ml4 { margin-left: 4px; }
  .lt_mr4 { margin-right: 4px; }
  .lt_mb4 { margin-bottom: 4px; }
  .lt_m5 { margin: 5px; }
  .lt_mt5 { margin-top: 5px; }
  .lt_ml5 { margin-left: 5px; }
  .lt_mr5 { margin-right: 5px; }
  .lt_mb5 { margin-bottom: 5px; }
  .lt_m7 { margin: 7px; }
  .lt_mt7 { margin-top: 7px; }
  .lt_ml7 { margin-left: 7px; }
  .lt_mr7 { margin-right: 7px; }
  .lt_mb7 { margin-bottom: 7px; }
  .lt_m10 { margin: 10px; }
  .lt_mt10 { margin-top: 10px; }
  .lt_ml10 { margin-left: 10px; }
  .lt_mr10 { margin-right: 10px; }
  .lt_mb10 { margin-bottom: 10px; }
  .lt_m13 { margin: 13px; }
  .lt_mt13 { margin-top: 13px; }
  .lt_ml13 { margin-left: 13px; }
  .lt_mr13 { margin-right: 13px; }
  .lt_mb13 { margin-bottom: 13px; }
  .lt_m15 { margin: 15px; }
  .lt_mt15 { margin-top: 15px; }
  .lt_ml15 { margin-left: 15px; }
  .lt_mr15 { margin-right: 15px; }
  .lt_mb15 { margin-bottom: 15px; }
  .lt_m20 { margin: 20px; }
  .lt_mt20 { margin-top: 20px; }
  .lt_ml20 { margin-left: 20px; }
  .lt_mr20 { margin-right: 20px; }
  .lt_mb20 { margin-bottom: 20px; }
  .lt_m25 { margin: 25px; }
  .lt_mt25 { margin-top: 25px; }
  .lt_ml25 { margin-left: 25px; }
  .lt_mr25 { margin-right: 25px; }
  .lt_mb25 { margin-bottom: 25px; }
  .lt_m30 { margin: 30px; }
  .lt_mt30 { margin-top: 30px; }
  .lt_ml30 { margin-left: 30px; }
  .lt_mr30 { margin-right: 30px; }
  .lt_mb30 { margin-bottom: 30px; }
  .lt_m35 { margin: 35px; }
  .lt_mt35 { margin-top: 35px; }
  .lt_ml35 { margin-left: 35px; }
  .lt_mr35 { margin-right: 35px; }
  .lt_mb35 { margin-bottom: 35px; }
  .lt_m-1 { margin: -1px; }
  .lt_mt-1 { margin-top: -1px; }
  .lt_ml-1 { margin-left: -1px; }
  .lt_mr-1 { margin-right: -1px; }
  .lt_mb-1 { margin-bottom: -1px; }
  .lt_m-2 { margin: -2px; }
  .lt_mt-2 { margin-top: -2px; }
  .lt_ml-2 { margin-left: -2px; }
  .lt_mr-2 { margin-right: -2px; }
  .lt_mb-2 { margin-bottom: -2px; }
  .lt_m-3 { margin: -3px; }
  .lt_mt-3 { margin-top: -3px; }
  .lt_ml-3 { margin-left: -3px; }
  .lt_mr-3 { margin-right: -3px; }
  .lt_mb-3 { margin-bottom: -3px; }
  .lt_m-4 { margin: -4px; }
  .lt_mt-4 { margin-top: -4px; }
  .lt_ml-4 { margin-left: -4px; }
  .lt_mr-4 { margin-right: -4px; }
  .lt_mb-4 { margin-bottom: -4px; }
  .lt_m-5 { margin: -5px; }
  .lt_mt-5 { margin-top: -5px; }
  .lt_ml-5 { margin-left: -5px; }
  .lt_mr-5 { margin-right: -5px; }
  .lt_mb-5 { margin-bottom: -5px; }
  .lt_m-7 { margin: -7px; }
  .lt_mt-7 { margin-top: -7px; }
  .lt_ml-7 { margin-left: -7px; }
  .lt_mr-7 { margin-right: -7px; }
  .lt_mb-7 { margin-bottom: -7px; }
  .lt_m-10 { margin: -10px; }
  .lt_mt-10 { margin-top: -10px; }
  .lt_ml-10 { margin-left: -10px; }
  .lt_mr-10 { margin-right: -10px; }
  .lt_mb-10 { margin-bottom: -10px; }
  .lt_m-13 { margin: -13px; }
  .lt_mt-13 { margin-top: -13px; }
  .lt_ml-13 { margin-left: -13px; }
  .lt_mr-13 { margin-right: -13px; }
  .lt_mb-13 { margin-bottom: -13px; }
  .lt_m-15 { margin: -15px; }
  .lt_mt-15 { margin-top: -15px; }
  .lt_ml-15 { margin-left: -15px; }
  .lt_mr-15 { margin-right: -15px; }
  .lt_mb-15 { margin-bottom: -15px; }
  .lt_m-20 { margin: -20px; }
  .lt_mt-20 { margin-top: -20px; }
  .lt_ml-20 { margin-left: -20px; }
  .lt_mr-20 { margin-right: -20px; }
  .lt_mb-20 { margin-bottom: -20px; }
  .lt_m-25 { margin: -25px; }
  .lt_mt-25 { margin-top: -25px; }
  .lt_ml-25 { margin-left: -25px; }
  .lt_mr-25 { margin-right: -25px; }
  .lt_mb-25 { margin-bottom: -25px; }
  .lt_m-30 { margin: -30px; }
  .lt_mt-30 { margin-top: -30px; }
  .lt_ml-30 { margin-left: -30px; }
  .lt_mr-30 { margin-right: -30px; }
  .lt_mb-30 { margin-bottom: -30px; }
  .lt_m-35 { margin: -35px; }
  .lt_mt-35 { margin-top: -35px; }
  .lt_ml-35 { margin-left: -35px; }
  .lt_mr-35 { margin-right: -35px; }
  .lt_mb-35 { margin-bottom: -35px; }


  /* Paddings */
  /* ======== */
  .lt_p0 { padding: 0 !important; }
  .lt_pt0 { padding-top: 0 !important; }
  .lt_pl0 { padding-left: 0 !important; }
  .lt_pr0 { padding-right: 0 !important; }
  .lt_pb0 { padding-bottom: 0 !important; }
  .lt_p1 { padding: 1px !important; }
  .lt_pt1 { padding-top: 1px !important; }
  .lt_pl1 { padding-left: 1px !important; }
  .lt_pr1 { padding-right: 1px !important; }
  .lt_pb1 { padding-bottom: 1px !important; }
  .lt_p2 { padding: 2px !important; }
  .lt_pt2 { padding-top: 2px !important; }
  .lt_pl2 { padding-left: 2px !important; }
  .lt_pr2 { padding-right: 2px !important; }
  .lt_pb2 { padding-bottom: 2px !important; }
  .lt_p3 { padding: 3px !important; }
  .lt_pt3 { padding-top: 3px !important; }
  .lt_pl3 { padding-left: 3px !important; }
  .lt_pr3 { padding-right: 3px !important; }
  .lt_pb3 { padding-bottom: 3px !important; }
  .lt_p4 { padding: 4px !important; }
  .lt_pt4 { padding-top: 4px !important; }
  .lt_pl4 { padding-left: 4px !important; }
  .lt_pr4 { padding-right: 4px !important; }
  .lt_pb4 { padding-bottom: 4px !important; }
  .lt_p5 { padding: 5px !important; }
  .lt_pt5 { padding-top: 5px !important; }
  .lt_pl5 { padding-left: 5px !important; }
  .lt_pr5 { padding-right: 5px !important; }
  .lt_pb5 { padding-bottom: 5px !important; }
  .lt_p7 { padding: 7px !important; }
  .lt_pt7 { padding-top: 7px !important; }
  .lt_pl7 { padding-left: 7px !important; }
  .lt_pr7 { padding-right: 7px !important; }
  .lt_pb7 { padding-bottom: 7px !important; }
  .lt_p10 { padding: 10px !important; }
  .lt_pt10 { padding-top: 10px !important; }
  .lt_pl10 { padding-left: 10px !important; }
  .lt_pr10 { padding-right: 10px !important; }
  .lt_pb10 { padding-bottom: 10px !important; }
  .lt_p13 { padding: 13px !important; }
  .lt_pt13 { padding-top: 13px !important; }
  .lt_pl13 { padding-left: 13px !important; }
  .lt_pr13 { padding-right: 13px !important; }
  .lt_pb13 { padding-bottom: 13px !important; }
  .lt_p15 { padding: 15px !important; }
  .lt_pt15 { padding-top: 15px !important; }
  .lt_pl15 { padding-left: 15px !important; }
  .lt_pr15 { padding-right: 15px !important; }
  .lt_pb15 { padding-bottom: 15px !important; }
  .lt_p20 { padding: 20px !important; }
  .lt_pt20 { padding-top: 20px !important; }
  .lt_pl20 { padding-left: 20px !important; }
  .lt_pr20 { padding-right: 20px !important; }
  .lt_pb20 { padding-bottom: 20px !important; }
  .lt_p25 { padding: 25px !important; }
  .lt_pt25 { padding-top: 25px !important; }
  .lt_pl25 { padding-left: 25px !important; }
  .lt_pr25 { padding-right: 25px !important; }
  .lt_pb25 { padding-bottom: 25px !important; }
  .lt_p30 { padding: 30px !important; }
  .lt_pt30 { padding-top: 30px !important; }
  .lt_pl30 { padding-left: 30px !important; }
  .lt_pr30 { padding-right: 30px !important; }
  .lt_pb30 { padding-bottom: 30px !important; }
  .lt_p35 { padding: 35px !important; }
  .lt_pt35 { padding-top: 35px !important; }
  .lt_pl35 { padding-left: 35px !important; }
  .lt_pr35 { padding-right: 35px !important; }
  .lt_pb35 { padding-bottom: 35px !important; }

  .lt_pt10p { padding-top: 10% !important; }
  .lt_pt15p { padding-top: 15% !important; }
  .lt_pt20p { padding-top: 20% !important; }
  .lt_pt25p { padding-top: 25% !important; }
  .lt_pt33p { padding-top: 33.33333333% !important; }
  .lt_pt35p { padding-top: 35% !important; }
  .lt_pt40p { padding-top: 40% !important; }
  .lt_pt50p { padding-top: 50% !important; }
  .lt_pt60p { padding-top: 60% !important; }
  .lt_pt65p { padding-top: 65% !important; }
  .lt_pt66p { padding-top: 66.66666666% !important; }
  .lt_pt70p { padding-top: 70% !important; }
  .lt_pt75p { padding-top: 75% !important; }
  .lt_pt80p { padding-top: 80% !important; }
  .lt_pt85p { padding-top: 85% !important; }
  .lt_pt90p { padding-top: 90% !important; }
  .lt_pt100p { padding-top: 100% !important; }


  /* Aligning */
  /* ======== */
  /* Vertical align */
  .lt_vat { vertical-align: top; }
  .lt_vam { vertical-align: middle; }
  .lt_vab { vertical-align: bottom; }
  .lt_vabl { vertical-align: baseline; }

  /* Horizontal text align */
  .lt_tal { text-align: left; }
  .lt_tal_i { text-align: left !important; }
  .lt_tac { text-align: center; }
  .lt_tac_i { text-align: center !important; }
  .lt_tar { text-align: right; }
  .lt_tar_i { text-align: right !important; }
  .lt_taj { text-align: justify; }
  .lt_taj_i { text-align: justify !important; }


  /* Text styling */
  /* ============ */
  /* Font style*/
  .lt_fsi { font-style: italic; }

  /* Font size */
  .lt_fz0 { font-size: 0; }
  .lt_fz10, .lt_fz10_a, .lt_fz10_a * { font-size: 10px; }
  .lt_fz11, .lt_fz11_a, .lt_fz11_a * { font-size: 11px; }
  .lt_fz12, .lt_fz12_a, .lt_fz12_a * { font-size: 12px; }
  .lt_fz13, .lt_fz13_a, .lt_fz13_a * { font-size: 13px; }
  .lt_fz14, .lt_fz14_a, .lt_fz14_a * { font-size: 14px; }
  .lt_fz15 { font-size: 15px; }
  .lt_fz16 { font-size: 16px; }
  .lt_fz18 { font-size: 18px; }
  .lt_fz20 { font-size: 20px; }
  .lt_fz22 { font-size: 22px; }
  .lt_fz24 { font-size: 24px; }
  .lt_fz26 { font-size: 26px; }
  .lt_fz28 { font-size: 28px; }
  .lt_fz30 { font-size: 30px; }
  .lt_fz32 { font-size: 32px; }
  .lt_fz34 { font-size: 34px; }
  .lt_fz36 { font-size: 36px; }
  .lt_fz38 { font-size: 38px; }
  .lt_fz40 { font-size: 40px; }
  .lt_fz42 { font-size: 42px; }
  .lt_fz44 { font-size: 44px; }
  .lt_fz46 { font-size: 46px; }
  .lt_fz48 { font-size: 48px; }
  .lt_fz50 { font-size: 50px; }
  .lt_fz0_i { font-size: 0 !important; }
  .lt_fz10_i, .lt_fz10_a_i, .lt_fz10_a_i * { font-size: 10px !important; }
  .lt_fz11_i, .lt_fz11_a_i, .lt_fz11_a_i * { font-size: 11px !important; }
  .lt_fz12_i, .lt_fz12_a_i, .lt_fz12_a_i * { font-size: 12px !important; }
  .lt_fz13_i, .lt_fz13_a_i, .lt_fz13_a_i * { font-size: 13px !important; }
  .lt_fz14_i, .lt_fz14_a_i, .lt_fz14_a_i * { font-size: 14px !important; }
  .lt_fz15_i { font-size: 15px !important; }
  .lt_fz16_i { font-size: 16px !important; }
  .lt_fz18_i { font-size: 18px !important; }
  .lt_fz20_i { font-size: 20px !important; }
  .lt_fz22_i { font-size: 22px !important; }
  .lt_fz24_i { font-size: 24px !important; }
  .lt_fz26_i { font-size: 26px !important; }
  .lt_fz28_i { font-size: 28px !important; }
  .lt_fz30_i { font-size: 30px !important; }
  .lt_fz32_i { font-size: 32px !important; }
  .lt_fz34_i { font-size: 34px !important; }
  .lt_fz36_i { font-size: 36px !important; }
  .lt_fz38_i { font-size: 38px !important; }
  .lt_fz40_i { font-size: 40px !important; }
  .lt_fz42_i { font-size: 42px !important; }
  .lt_fz44_i { font-size: 44px !important; }
  .lt_fz46_i { font-size: 46px !important; }
  .lt_fz48_i { font-size: 48px !important; }
  .lt_fz50_i { font-size: 50px !important; }

  /* Font weight */
  .lt_fw900, .lt_fw900_a, .lt_fw900_a * { font-family: 'MuseoSansCyrl-900', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 900; }
  .lt_fw900_i, .lt_fw900_a_i, .lt_fw900_a_i * { font-family: 'MuseoSansCyrl-900', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 900 !important; }
  .lt_fw700, .lt_fw700_a, .lt_fw700_a *, .bold, .bold_a, .bold_a *, .semibold, .semibold_a, .semibold_a * { font-family: 'MuseoSansCyrl-700', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 700; }
  .lt_fw700_i, .lt_fw700_a_i, .lt_fw700_a_i *, .bold_i, .bold_a_i, .bold_a_i *, .semibold_i, .semibold_a_i, .semibold_a_i * { font-family: 'MuseoSansCyrl-700', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 700 !important; }
  .lt_fw400, .lt_fw400_a, .lt_fw400_a * { font-family: 'MuseoSansCyrl-500', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 400; }
  .lt_fw400_i, .lt_fw400_a_i, .lt_fw400_a_i * { font-family: 'MuseoSansCyrl-500', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 400 !important; }
  .lt_fw300, .lt_fw300_a, .lt_fw300_a *, .semilight, .semilight_a, .semilight_a * { font-family: 'MuseoSansCyrl-300', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 300; }
  .lt_fw300_i, .lt_fw300_a_i, .lt_fw300_a_i *, .semilight_i, .semilight_a_i, .semilight_a_i * { font-family: 'MuseoSansCyrl-300', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 300 !important; }

  /* Line height */
  .lt_lh10 { line-height: 1; }
  .lt_lh100p { line-height: 100%; }
  .lt_lh11 { line-height: 1.1; }
  .lt_lh110p { line-height: 110%; }
  .lt_lh12 { line-height: 1.2; }
  .lt_lh120p { line-height: 120%; }
  .lt_lh13 { line-height: 1.3; }
  .lt_lh130p { line-height: 130%; }
  .lt_lh14 { line-height: 1.4; }
  .lt_lh140p { line-height: 140%; }
  .lt_lh15 { line-height: 1.5; }
  .lt_lh150p { line-height: 150%; }
  .lt_lh16 { line-height: 1.6; }
  .lt_lh160p { line-height: 160%; }
  .lt_lh17 { line-height: 1.7; }
  .lt_lh170p { line-height: 170%; }
  .lt_lh18 { line-height: 1.8; }
  .lt_lh180p { line-height: 180%; }
  .lt_lh19 { line-height: 1.9; }
  .lt_lh190p { line-height: 190%; }
  .lt_lh20 { line-height: 2; }
  .lt_lh200p { line-height: 200%; }


  /* Floating */
  /* ======== */
  .lt_fln { float: none; }
  .lt_fll { float: left; }
  .lt_flr { float: right; }
}
@media screen and (max-width: 1023px) {
  .menu-is-open {
    position: relative;
    overflow: hidden !important;
  }



  .button-style {
    padding: 13px 22px;
  }



  header > .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
    header .header {
      order: 2;
      flex-grow: 1;
    }
      header .logo {
        flex-shrink: 1;
        padding-right: 0px;
      }
        header .logo img {
          width: 100%;
        }


      .header__center {
        flex-grow: 0;
        border: none;
      }
        .header__center-first {
          font-size: 10px;
          line-height: 1.1;
          min-width: 160px;
          max-width: 165px;
          margin-left: 8px;
        }

        .header__center-second,
        .header__phones > a {
          display: none;
        }

        .header__phones {
          border: none;
          padding-left: 0px;
          margin-left: 10px;
        }
          .header__phones .call-mob {
            display: unset;
            line-height: 0;
          }



      .header-menu-wrapper {
        align-self: center;
        order: 1;
      }
        .header-menu-button-wrapper {
          display: block;
          padding-right: 20px;
        }
          .header-menu-button-wrapper:before {
            content: '';
            display: block;
            background: #000;
            position: fixed;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            z-index: 97;

            opacity: 0;
            visibility: hidden;

            -webkit-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out .3s;
            -moz-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out .3s;
            -o-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out .3s;
            transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out .3s;
          }
            .menu-is-open .header-menu-button-wrapper:before {
              opacity: .6;
              visibility: visible;

              -webkit-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out 0s;
              -moz-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out 0s;
              -o-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out 0s;
              transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out 0s;
            }


          .header-menu-button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
            width: 30px;
            min-width: 30px;
            padding: 7px 0px;
            position: relative;
            cursor: pointer;
          }
            .header-menu-button:before,
            .header-menu-button:after,
            .header-menu-button span {
              content: '';
              font-size: 0px;
              background: #000;
              border-radius: 9px;
              height: 2px;
              padding: 0px;
              position: relative;
            }

            .header-menu-button span {
              margin: 6px 0px;
            }


        .header-menu-button-wrapper + div {
          background: none;
          width: max-content;
          min-width: 340px;
          max-width: 100%;
          padding: 0px 40px 0px 0px;
          top: 0px;
          right: 100%;
          bottom: 0px;
          position: fixed;
          z-index: 99;

          transition: transform .25s ease-in-out 0s;
        }
          .menu-is-open .header-menu-button-wrapper + div {
            transform: translateX(100%);
          }

          .header-menu-button-wrapper + div:before {
            content: '';
            display: block;
            background: url('/i/icon-cross.svg') no-repeat center right 15px/15px #e81d28;
            border-radius: 0px 50% 50% 0px;
            width: 40px;
            min-width: 40px;
            height: 37px;
            position: absolute;
            cursor: pointer;
            top: 10px;
            right: 0px;
            z-index: 98;
          }


          .header-menu {
            background: #fff;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
            height: 100%;
            padding: 15px 10px;
            overflow: hidden;
            z-index: 98;
          }
            .header-menu > ul {
              display: block;
              height: 100%;
              overflow: auto;
            }
              .header-menu ul > li > div {
                width: 100%;
              }
                .header-menu ul > li > div > div {
                  width: 100%;
                  padding: 0px;
                }
                  .header-menu .dropmarker {
                    display: block;
                    font-size: 0px;
                    width: 38px;
                    min-width: 38px;
                    margin: 0px;
                    cursor: pointer;
                    position: relative;

                    -webkit-transition: background .2s ease-in-out 0s;
                    -moz-transition: background .2s ease-in-out 0s;
                    -o-transition: background .2s ease-in-out 0s;
                    transition: background .2s ease-in-out 0s;
                  }
                    .header-menu li.is-hovered > div > div > .dropmarker {
                      background-color: rgba(51,51,51,.03);
                    }

                    .header-menu .dropmarker:before {
                      content: '';
                      display: block;
                      margin: auto;
                      background: url('/i/arrow-down.svg') no-repeat 0px 50%/contain transparent;
                      width: 14px;
                      height: 14px;
                      top: 0px;
                      left: 0px;
                      right: 0px;
                      bottom: 0px;
                      position: absolute;

                      transition: transform .25s ease-in-out 0s;
                    }
                      .header-menu li.is-hovered > div > div > .dropmarker:before {
                        transform: rotate(180deg);
                      }


                .header-menu ul > li > div > div > div:first-child {
                  width: 100%;
                }
                  .header-menu ul div > a,
                  .header-menu ul div > span {
                    display: block;
                    padding: 10px 20px 10px 0px !important;
                    cursor: pointer;
                  }



              .header-menu > ul ul,
              .header-menu > ul > li > div > ul,
              .header-menu > ul > li > div:hover > ul {
                display: none;
                background: rgba(51,51,51,.03);
                box-shadow: none;
                top: unset;
                left: unset;
                min-width: unset;
                padding: 0px 0px 0px 15px;
                margin: 0px 0px 15px;
                position: unset;
                overflow: hidden;
                visibility: visible;
                opacity: 1;
                white-space: normal;

                transition: none;
              }




  .subheader {
    font-size: 26px;
    padding: 28px 0px 35px;
  }

  .subsubheader {
    font-size: 22px;
    padding: 18px 0px 20px;
  }



  .first-block {
    padding: 0px 10px 210px;
  }
    .max-bg,
    .glass-bg {
      display: none;
    }

    .max-bg-mob {
      display: block;
      right: 0;
      bottom: -5px;
      position: absolute;
      z-index: 1;
    }

    .mob-glass {
      display: block;
      left: 0;
      bottom: 0;
      position: absolute;
    }

    .first-block_slogan {
      font-size: 22px;
      padding-top: 50px;
    }

    .first-block_title {
      font-size: 24px;
      margin: 15px 0px 25px;
    }

    .first-block_border {
      border: 1px dashed #fff;
      border-radius: 5px;
      width: unset;
      padding: 22px 20px 25px;
      margin: 40px 0px 0px;
    }
      .border-bg {
        display: none;
      }

      .first-block_border-title {
        font-size: 20px;
      }

      .first-block_border-subtitle {
        font-size: 16px;
        margin-bottom: 15px;
      }


    .todo {
      display: inline-flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 20px 25px 22px 20px;
      margin: 55px 80px 0px 0px;
    }
      .todo-next {
        width: unset;
        min-width: unset;
        margin: 3px 0px 3px -3px;
        transform: rotate(90deg);
      }
        .todo-next img {
          width: 18px;
          height: 18px;
        }



  .text-container {
    padding: 0px 0px 40px;
  }



  .vitrine {
    padding: 0px 0px 35px;
  }
    .vitrine-item {
      width: 50%;
    }
      .vitrine-item > div {
        padding: 30px 15px 35px;
      }
        .vitrine-item-text {
          padding: 20px 5% 0px;
        }


  .advantages .vitrine-item-text {
    padding-top: 25px;
  }



  .incut-image,
  .incut {
    padding: 0px 0px 35px;
  }
    .incut-image:after {
      background-image: url('/i/call-block-mob-upd.png');
      min-width: 150px;
      height: 80px;
    }

    .incut-image > div,
    .incut > div {
      padding: 25px 30px 25px 28px;
    }



  .tab-list {
    padding: 0px 0px 25px;
  }



  .price-table {
    padding: 0px 0px 35px;
  }



  .contact-info-container > div {
    width: 50%;
  }



  footer {
    padding-bottom: 55px;
  }
    footer .header {
      justify-content: center;
    }
      footer .header__center,
      footer .header__phones {
        display: none;
      }

      footer .info {
        padding-right: 0px;
      }


    .footer-bottom > div {
      justify-content: space-around;
    }



  .spam {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #24bb17;
    font-family: 'MuseoSansCyrl-700', 'Helvetica', 'Tahoma', 'Arial', sans-serif;
    font-weight: 700;
    font-size: 14px;
    height: 50px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: fixed;
    z-index: 5;

    -webkit-animation: glowing 2300ms infinite;
    -o-animation: glowing 2300ms infinite;
    animation: glowing 2300ms infinite;
  }
    @keyframes glowing {
      0% {
        background-color: #2ba805;
        box-shadow: 0 0 5px #2ba805;
      }
      50% {
        background-color: #50c42c;
        box-shadow: 0 0 20px #49e819;
      }
      100% {
        background-color: #2ba805;
        box-shadow: 0 0 5px #2ba805;
      }
    }

    .spam img {
      width: 26px;
      height: 25px;
      margin-right: 12px;
    }
}

/* portrait tablet - pt */
@media screen and (max-width: 979px) {
  /* Border */
  /* ====== */
  /* Border width */
  .pt_brw0 { border-width: 0px; }
  .pt_brtw0 { border-top-width: 0px; }
  .pt_brlw0 { border-left-width: 0px; }
  .pt_brrw0 { border-right-width: 0px; }
  .pt_brbw0 { border-bottom-width: 0px; }
  .pt_brw1 { border-width: 1px; }
  .pt_brtw1 { border-top-width: 1px; }
  .pt_brlw1 { border-left-width: 1px; }
  .pt_brrw1 { border-right-width: 1px; }
  .pt_brbw1 { border-bottom-width: 1px; }

  /* Border style */
  .pt_brstn { border-style: none; }
  .pt_brsts { border-style: solid; }
  .pt_brstd { border-style: dashed; }


  /* Display */
  /* ======= */
  .pt_dn { display: none; }
  .pt_dn_i { display: none !important; }
  .pt_db { display: block; }
  .pt_di { display: inline; }
  .pt_dib { display: inline-block; }
  .pt_dt { display: table; }
  .pt_dtr { display: table-row; }
  .pt_dtc { display: table-cell; }
  .pt_du { display: unset; }

  /* Flex */
  .pt_dfdr { display: flex; flex-direction: row; }
  .pt_dfdrr { display: flex; flex-direction: row-reverse; }
  .pt_dfdc { display: flex; flex-direction: column; }
  .pt_dfdcr { display: flex; flex-direction: column-reverse; }
  .pt_difdr { display: inline-flex; flex-direction: row; }
  .pt_difdrr { display: inline-flex; flex-direction: row-reverse; }
  .pt_difdc { display: inline-flex; flex-direction: column; }
  .pt_difdcr { display: inline-flex; flex-direction: column-reverse; }
  .pt_fwn { flex-wrap: nowrap; }
  .pt_fww { flex-wrap: wrap; }
  .pt_fwwr { flex-wrap: wrap-reverse; }
  .pt_fjcs { justify-content: flex-start; }
  .pt_fjce { justify-content: flex-end; }
  .pt_fjcc { justify-content: center; }
  .pt_fjcsa { justify-content: space-around; }
  .pt_fjcsb { justify-content: space-between; }
  .pt_fais { align-items: flex-start; }
  .pt_faie { align-items: flex-end; }
  .pt_faic { align-items: center; }
  .pt_faib { align-items: baseline; }
  .pt_faist { align-items: stretch; }
  .pt_facs { align-content: flex-start; }
  .pt_face { align-content: flex-end; }
  .pt_facc { align-content: center; }
  .pt_facsa { align-content: space-around; }
  .pt_facsb { align-content: space-between; }
  .pt_facst { align-content: stretch; }
  .pt_fass { align-self: flex-start; }
  .pt_fase { align-self: flex-end; }
  .pt_fasc { align-self: center; }
  .pt_fasb { align-self: baseline; }
  .pt_fasst { align-self: stretch; }
  .pt_fg0 { flex-grow: 0; }
  .pt_fg1 { flex-grow: 1; }
  .pt_fsh0 { flex-shrink: 0; }
  .pt_fsh1 { flex-shrink: 1; }


  /* Height */
  /* ====== */
  .pt_h0 { height: 0; }
  .pt_h100p, .pt_hfull { height: 100%; }
  .pt_hauto { height: auto; }


  /* Width */
  /* ===== */
  .pt_w0 { width: 0; }
  .pt_w1 { width: 1px; }
  .pt_w5p { width: 5%; }
  .pt_w7p { width: 7%; }
  .pt_w10p { width: 10%; }
  .pt_w12p { width: 12.5%; }
  .pt_w15p { width: 15%; }
  .pt_w20p { width: 20%; }
  .pt_w25p { width: 25%; }
  .pt_w33p { width: 33.33333333%; }
  .pt_w35p { width: 35%; }
  .pt_w40p { width: 40%; }
  .pt_w50p { width: 50%; }
  .pt_w60p { width: 60%; }
  .pt_w65p { width: 65%; }
  .pt_w66p { width: 66.66666666%; }
  .pt_w70p { width: 70%; }
  .pt_w75p { width: 75%; }
  .pt_w80p { width: 80%; }
  .pt_w85p { width: 85%; }
  .pt_w88p { width: 87.5%; }
  .pt_w90p { width: 90%; }
  .pt_w93p { width: 93%; }
  .pt_w95p { width: 95%; }
  .pt_w100p, .pt_wfull { width: 100%; }
  .pt_wauto { width: auto; }


  /* Margins */
  /* ======= */
  .pt_m0auto, .pt_m0a { margin: 0 auto; }
  .pt_m0auto_i, .pt_m0a_i { margin: 0 auto !important; }
  .pt_mauto0, .pt_ma0 { margin: auto 0; }
  .pt_mauto0_i, .pt_ma0_i { margin: auto 0 !important; }
  .pt_mauto, .pt_ma { margin: auto; }
  .pt_mauto_i, .pt_ma_i { margin: auto !important; }
  .pt_mtauto, .pt_mta { margin-top: auto; }
  .pt_mtauto_i, .pt_mta_i { margin-top: auto !important; }
  .pt_mlauto, .pt_mla { margin-left: auto; }
  .pt_mlauto_i, .pt_mla_i { margin-left: auto !important; }
  .pt_mrauto, .pt_mra { margin-right: auto; }
  .pt_mrauto_i, .pt_mra_i { margin-right: auto !important; }
  .pt_mbauto, .pt_mba { margin-bottom: auto; }
  .pt_mbauto_i, .pt_mba_i { margin-bottom: auto !important; }
  .pt_m0 { margin: 0; }
  .pt_m0_i { margin: 0 !important; }
  .pt_mt0 { margin-top: 0; }
  .pt_mt0_i { margin-top: 0 !important; }
  .pt_ml0 { margin-left: 0; }
  .pt_ml0_i { margin-left: 0 !important; }
  .pt_mr0 { margin-right: 0; }
  .pt_mr0_i { margin-right: 0 !important; }
  .pt_mb0 { margin-bottom: 0; }
  .pt_mb0_i { margin-bottom: 0 !important; }
  .pt_m1 { margin: 1px; }
  .pt_mt1 { margin-top: 1px; }
  .pt_ml1 { margin-left: 1px; }
  .pt_mr1 { margin-right: 1px; }
  .pt_mb1 { margin-bottom: 1px; }
  .pt_m2 { margin: 2px; }
  .pt_mt2 { margin-top: 2px; }
  .pt_ml2 { margin-left: 2px; }
  .pt_mr2 { margin-right: 2px; }
  .pt_mb2 { margin-bottom: 2px; }
  .pt_m3 { margin: 3px; }
  .pt_mt3 { margin-top: 3px; }
  .pt_ml3 { margin-left: 3px; }
  .pt_mr3 { margin-right: 3px; }
  .pt_mb3 { margin-bottom: 3px; }
  .pt_m4 { margin: 4px; }
  .pt_mt4 { margin-top: 4px; }
  .pt_ml4 { margin-left: 4px; }
  .pt_mr4 { margin-right: 4px; }
  .pt_mb4 { margin-bottom: 4px; }
  .pt_m5 { margin: 5px; }
  .pt_mt5 { margin-top: 5px; }
  .pt_ml5 { margin-left: 5px; }
  .pt_mr5 { margin-right: 5px; }
  .pt_mb5 { margin-bottom: 5px; }
  .pt_m7 { margin: 7px; }
  .pt_mt7 { margin-top: 7px; }
  .pt_ml7 { margin-left: 7px; }
  .pt_mr7 { margin-right: 7px; }
  .pt_mb7 { margin-bottom: 7px; }
  .pt_m10 { margin: 10px; }
  .pt_mt10 { margin-top: 10px; }
  .pt_ml10 { margin-left: 10px; }
  .pt_mr10 { margin-right: 10px; }
  .pt_mb10 { margin-bottom: 10px; }
  .pt_m13 { margin: 13px; }
  .pt_mt13 { margin-top: 13px; }
  .pt_ml13 { margin-left: 13px; }
  .pt_mr13 { margin-right: 13px; }
  .pt_mb13 { margin-bottom: 13px; }
  .pt_m15 { margin: 15px; }
  .pt_mt15 { margin-top: 15px; }
  .pt_ml15 { margin-left: 15px; }
  .pt_mr15 { margin-right: 15px; }
  .pt_mb15 { margin-bottom: 15px; }
  .pt_m20 { margin: 20px; }
  .pt_mt20 { margin-top: 20px; }
  .pt_ml20 { margin-left: 20px; }
  .pt_mr20 { margin-right: 20px; }
  .pt_mb20 { margin-bottom: 20px; }
  .pt_m25 { margin: 25px; }
  .pt_mt25 { margin-top: 25px; }
  .pt_ml25 { margin-left: 25px; }
  .pt_mr25 { margin-right: 25px; }
  .pt_mb25 { margin-bottom: 25px; }
  .pt_m30 { margin: 30px; }
  .pt_mt30 { margin-top: 30px; }
  .pt_ml30 { margin-left: 30px; }
  .pt_mr30 { margin-right: 30px; }
  .pt_mb30 { margin-bottom: 30px; }
  .pt_m35 { margin: 35px; }
  .pt_mt35 { margin-top: 35px; }
  .pt_ml35 { margin-left: 35px; }
  .pt_mr35 { margin-right: 35px; }
  .pt_mb35 { margin-bottom: 35px; }
  .pt_m-1 { margin: -1px; }
  .pt_mt-1 { margin-top: -1px; }
  .pt_ml-1 { margin-left: -1px; }
  .pt_mr-1 { margin-right: -1px; }
  .pt_mb-1 { margin-bottom: -1px; }
  .pt_m-2 { margin: -2px; }
  .pt_mt-2 { margin-top: -2px; }
  .pt_ml-2 { margin-left: -2px; }
  .pt_mr-2 { margin-right: -2px; }
  .pt_mb-2 { margin-bottom: -2px; }
  .pt_m-3 { margin: -3px; }
  .pt_mt-3 { margin-top: -3px; }
  .pt_ml-3 { margin-left: -3px; }
  .pt_mr-3 { margin-right: -3px; }
  .pt_mb-3 { margin-bottom: -3px; }
  .pt_m-4 { margin: -4px; }
  .pt_mt-4 { margin-top: -4px; }
  .pt_ml-4 { margin-left: -4px; }
  .pt_mr-4 { margin-right: -4px; }
  .pt_mb-4 { margin-bottom: -4px; }
  .pt_m-5 { margin: -5px; }
  .pt_mt-5 { margin-top: -5px; }
  .pt_ml-5 { margin-left: -5px; }
  .pt_mr-5 { margin-right: -5px; }
  .pt_mb-5 { margin-bottom: -5px; }
  .pt_m-7 { margin: -7px; }
  .pt_mt-7 { margin-top: -7px; }
  .pt_ml-7 { margin-left: -7px; }
  .pt_mr-7 { margin-right: -7px; }
  .pt_mb-7 { margin-bottom: -7px; }
  .pt_m-10 { margin: -10px; }
  .pt_mt-10 { margin-top: -10px; }
  .pt_ml-10 { margin-left: -10px; }
  .pt_mr-10 { margin-right: -10px; }
  .pt_mb-10 { margin-bottom: -10px; }
  .pt_m-13 { margin: -13px; }
  .pt_mt-13 { margin-top: -13px; }
  .pt_ml-13 { margin-left: -13px; }
  .pt_mr-13 { margin-right: -13px; }
  .pt_mb-13 { margin-bottom: -13px; }
  .pt_m-15 { margin: -15px; }
  .pt_mt-15 { margin-top: -15px; }
  .pt_ml-15 { margin-left: -15px; }
  .pt_mr-15 { margin-right: -15px; }
  .pt_mb-15 { margin-bottom: -15px; }
  .pt_m-20 { margin: -20px; }
  .pt_mt-20 { margin-top: -20px; }
  .pt_ml-20 { margin-left: -20px; }
  .pt_mr-20 { margin-right: -20px; }
  .pt_mb-20 { margin-bottom: -20px; }
  .pt_m-25 { margin: -25px; }
  .pt_mt-25 { margin-top: -25px; }
  .pt_ml-25 { margin-left: -25px; }
  .pt_mr-25 { margin-right: -25px; }
  .pt_mb-25 { margin-bottom: -25px; }
  .pt_m-30 { margin: -30px; }
  .pt_mt-30 { margin-top: -30px; }
  .pt_ml-30 { margin-left: -30px; }
  .pt_mr-30 { margin-right: -30px; }
  .pt_mb-30 { margin-bottom: -30px; }
  .pt_m-35 { margin: -35px; }
  .pt_mt-35 { margin-top: -35px; }
  .pt_ml-35 { margin-left: -35px; }
  .pt_mr-35 { margin-right: -35px; }
  .pt_mb-35 { margin-bottom: -35px; }


  /* Paddings */
  /* ======== */
  .pt_p0 { padding: 0 !important; }
  .pt_pt0 { padding-top: 0 !important; }
  .pt_pl0 { padding-left: 0 !important; }
  .pt_pr0 { padding-right: 0 !important; }
  .pt_pb0 { padding-bottom: 0 !important; }
  .pt_p1 { padding: 1px !important; }
  .pt_pt1 { padding-top: 1px !important; }
  .pt_pl1 { padding-left: 1px !important; }
  .pt_pr1 { padding-right: 1px !important; }
  .pt_pb1 { padding-bottom: 1px !important; }
  .pt_p2 { padding: 2px !important; }
  .pt_pt2 { padding-top: 2px !important; }
  .pt_pl2 { padding-left: 2px !important; }
  .pt_pr2 { padding-right: 2px !important; }
  .pt_pb2 { padding-bottom: 2px !important; }
  .pt_p3 { padding: 3px !important; }
  .pt_pt3 { padding-top: 3px !important; }
  .pt_pl3 { padding-left: 3px !important; }
  .pt_pr3 { padding-right: 3px !important; }
  .pt_pb3 { padding-bottom: 3px !important; }
  .pt_p4 { padding: 4px !important; }
  .pt_pt4 { padding-top: 4px !important; }
  .pt_pl4 { padding-left: 4px !important; }
  .pt_pr4 { padding-right: 4px !important; }
  .pt_pb4 { padding-bottom: 4px !important; }
  .pt_p5 { padding: 5px !important; }
  .pt_pt5 { padding-top: 5px !important; }
  .pt_pl5 { padding-left: 5px !important; }
  .pt_pr5 { padding-right: 5px !important; }
  .pt_pb5 { padding-bottom: 5px !important; }
  .pt_p7 { padding: 7px !important; }
  .pt_pt7 { padding-top: 7px !important; }
  .pt_pl7 { padding-left: 7px !important; }
  .pt_pr7 { padding-right: 7px !important; }
  .pt_pb7 { padding-bottom: 7px !important; }
  .pt_p10 { padding: 10px !important; }
  .pt_pt10 { padding-top: 10px !important; }
  .pt_pl10 { padding-left: 10px !important; }
  .pt_pr10 { padding-right: 10px !important; }
  .pt_pb10 { padding-bottom: 10px !important; }
  .pt_p13 { padding: 13px !important; }
  .pt_pt13 { padding-top: 13px !important; }
  .pt_pl13 { padding-left: 13px !important; }
  .pt_pr13 { padding-right: 13px !important; }
  .pt_pb13 { padding-bottom: 13px !important; }
  .pt_p15 { padding: 15px !important; }
  .pt_pt15 { padding-top: 15px !important; }
  .pt_pl15 { padding-left: 15px !important; }
  .pt_pr15 { padding-right: 15px !important; }
  .pt_pb15 { padding-bottom: 15px !important; }
  .pt_p20 { padding: 20px !important; }
  .pt_pt20 { padding-top: 20px !important; }
  .pt_pl20 { padding-left: 20px !important; }
  .pt_pr20 { padding-right: 20px !important; }
  .pt_pb20 { padding-bottom: 20px !important; }
  .pt_p25 { padding: 25px !important; }
  .pt_pt25 { padding-top: 25px !important; }
  .pt_pl25 { padding-left: 25px !important; }
  .pt_pr25 { padding-right: 25px !important; }
  .pt_pb25 { padding-bottom: 25px !important; }
  .pt_p30 { padding: 30px !important; }
  .pt_pt30 { padding-top: 30px !important; }
  .pt_pl30 { padding-left: 30px !important; }
  .pt_pr30 { padding-right: 30px !important; }
  .pt_pb30 { padding-bottom: 30px !important; }
  .pt_p35 { padding: 35px !important; }
  .pt_pt35 { padding-top: 35px !important; }
  .pt_pl35 { padding-left: 35px !important; }
  .pt_pr35 { padding-right: 35px !important; }
  .pt_pb35 { padding-bottom: 35px !important; }

  .pt_pt10p { padding-top: 10% !important; }
  .pt_pt15p { padding-top: 15% !important; }
  .pt_pt20p { padding-top: 20% !important; }
  .pt_pt25p { padding-top: 25% !important; }
  .pt_pt33p { padding-top: 33.33333333% !important; }
  .pt_pt35p { padding-top: 35% !important; }
  .pt_pt40p { padding-top: 40% !important; }
  .pt_pt50p { padding-top: 50% !important; }
  .pt_pt60p { padding-top: 60% !important; }
  .pt_pt65p { padding-top: 65% !important; }
  .pt_pt66p { padding-top: 66.66666666% !important; }
  .pt_pt70p { padding-top: 70% !important; }
  .pt_pt75p { padding-top: 75% !important; }
  .pt_pt80p { padding-top: 80% !important; }
  .pt_pt85p { padding-top: 85% !important; }
  .pt_pt90p { padding-top: 90% !important; }
  .pt_pt100p { padding-top: 100% !important; }


  /* Aligning */
  /* ======== */
  /* Vertical align */
  .pt_vat { vertical-align: top; }
  .pt_vam { vertical-align: middle; }
  .pt_vab { vertical-align: bottom; }
  .pt_vabl { vertical-align: baseline; }

  /* Horizontal text align */
  .pt_tal { text-align: left; }
  .pt_tal_i { text-align: left !important; }
  .pt_tac { text-align: center; }
  .pt_tac_i { text-align: center !important; }
  .pt_tar { text-align: right; }
  .pt_tar_i { text-align: right !important; }
  .pt_taj { text-align: justify; }
  .pt_taj_i { text-align: justify !important; }


  /* Text styling */
  /* ============ */
  /* Font style*/
  .pt_fsi { font-style: italic; }

  /* Font size */
  .pt_fz0 { font-size: 0; }
  .pt_fz10, .pt_fz10_a, .pt_fz10_a * { font-size: 10px; }
  .pt_fz11, .pt_fz11_a, .pt_fz11_a * { font-size: 11px; }
  .pt_fz12, .pt_fz12_a, .pt_fz12_a * { font-size: 12px; }
  .pt_fz13, .pt_fz13_a, .pt_fz13_a * { font-size: 13px; }
  .pt_fz14, .pt_fz14_a, .pt_fz14_a * { font-size: 14px; }
  .pt_fz15 { font-size: 15px; }
  .pt_fz16 { font-size: 16px; }
  .pt_fz18 { font-size: 18px; }
  .pt_fz20 { font-size: 20px; }
  .pt_fz22 { font-size: 22px; }
  .pt_fz24 { font-size: 24px; }
  .pt_fz26 { font-size: 26px; }
  .pt_fz28 { font-size: 28px; }
  .pt_fz30 { font-size: 30px; }
  .pt_fz32 { font-size: 32px; }
  .pt_fz34 { font-size: 34px; }
  .pt_fz36 { font-size: 36px; }
  .pt_fz38 { font-size: 38px; }
  .pt_fz40 { font-size: 40px; }
  .pt_fz42 { font-size: 42px; }
  .pt_fz44 { font-size: 44px; }
  .pt_fz46 { font-size: 46px; }
  .pt_fz48 { font-size: 48px; }
  .pt_fz50 { font-size: 50px; }
  .pt_fz0_i { font-size: 0 !important; }
  .pt_fz10_i, .pt_fz10_a_i, .pt_fz10_a_i * { font-size: 10px !important; }
  .pt_fz11_i, .pt_fz11_a_i, .pt_fz11_a_i * { font-size: 11px !important; }
  .pt_fz12_i, .pt_fz12_a_i, .pt_fz12_a_i * { font-size: 12px !important; }
  .pt_fz13_i, .pt_fz13_a_i, .pt_fz13_a_i * { font-size: 13px !important; }
  .pt_fz14_i, .pt_fz14_a_i, .pt_fz14_a_i * { font-size: 14px !important; }
  .pt_fz15_i { font-size: 15px !important; }
  .pt_fz16_i { font-size: 16px !important; }
  .pt_fz18_i { font-size: 18px !important; }
  .pt_fz20_i { font-size: 20px !important; }
  .pt_fz22_i { font-size: 22px !important; }
  .pt_fz24_i { font-size: 24px !important; }
  .pt_fz26_i { font-size: 26px !important; }
  .pt_fz28_i { font-size: 28px !important; }
  .pt_fz30_i { font-size: 30px !important; }
  .pt_fz32_i { font-size: 32px !important; }
  .pt_fz34_i { font-size: 34px !important; }
  .pt_fz36_i { font-size: 36px !important; }
  .pt_fz38_i { font-size: 38px !important; }
  .pt_fz40_i { font-size: 40px !important; }
  .pt_fz42_i { font-size: 42px !important; }
  .pt_fz44_i { font-size: 44px !important; }
  .pt_fz46_i { font-size: 46px !important; }
  .pt_fz48_i { font-size: 48px !important; }
  .pt_fz50_i { font-size: 50px !important; }

  /* Font weight */
  .pt_fw900, .pt_fw900_a, .pt_fw900_a * { font-family: 'MuseoSansCyrl-900', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 900; }
  .pt_fw900_i, .pt_fw900_a_i, .pt_fw900_a_i * { font-family: 'MuseoSansCyrl-900', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 900 !important; }
  .pt_fw700, .pt_fw700_a, .pt_fw700_a *, .bold, .bold_a, .bold_a *, .semibold, .semibold_a, .semibold_a * { font-family: 'MuseoSansCyrl-700', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 700; }
  .pt_fw700_i, .pt_fw700_a_i, .pt_fw700_a_i *, .bold_i, .bold_a_i, .bold_a_i *, .semibold_i, .semibold_a_i, .semibold_a_i * { font-family: 'MuseoSansCyrl-700', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 700 !important; }
  .pt_fw400, .pt_fw400_a, .pt_fw400_a * { font-family: 'MuseoSansCyrl-500', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 400; }
  .pt_fw400_i, .pt_fw400_a_i, .pt_fw400_a_i * { font-family: 'MuseoSansCyrl-500', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 400 !important; }
  .pt_fw300, .pt_fw300_a, .pt_fw300_a *, .semilight, .semilight_a, .semilight_a * { font-family: 'MuseoSansCyrl-300', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 300; }
  .pt_fw300_i, .pt_fw300_a_i, .pt_fw300_a_i *, .semilight_i, .semilight_a_i, .semilight_a_i * { font-family: 'MuseoSansCyrl-300', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 300 !important; }

  /* Line height */
  .pt_lh10 { line-height: 1; }
  .pt_lh100p { line-height: 100%; }
  .pt_lh11 { line-height: 1.1; }
  .pt_lh110p { line-height: 110%; }
  .pt_lh12 { line-height: 1.2; }
  .pt_lh120p { line-height: 120%; }
  .pt_lh13 { line-height: 1.3; }
  .pt_lh130p { line-height: 130%; }
  .pt_lh14 { line-height: 1.4; }
  .pt_lh140p { line-height: 140%; }
  .pt_lh15 { line-height: 1.5; }
  .pt_lh150p { line-height: 150%; }
  .pt_lh16 { line-height: 1.6; }
  .pt_lh160p { line-height: 160%; }
  .pt_lh17 { line-height: 1.7; }
  .pt_lh170p { line-height: 170%; }
  .pt_lh18 { line-height: 1.8; }
  .pt_lh180p { line-height: 180%; }
  .pt_lh19 { line-height: 1.9; }
  .pt_lh190p { line-height: 190%; }
  .pt_lh20 { line-height: 2; }
  .pt_lh200p { line-height: 200%; }


  /* Floating */
  /* ======== */
  .pt_fln { float: none; }
  .pt_fll { float: left; }
  .pt_flr { float: right; }
}
@media screen and (max-width: 979px) {
}

@media screen and (max-width: 899px) {
  .form-wrapper {
    margin: -20px 0px 0px -20px;
  }
    .form-wrapper #form-success-message,
    .form-row,
    .form-button {
      padding: 20px 0px 0px 20px;
    }
}

/* landscape mobile - lm */
@media screen and (max-width: 767px) {
  /* Border */
  /* ====== */
  /* Border width */
  .lm_brw0 { border-width: 0px; }
  .lm_brtw0 { border-top-width: 0px; }
  .lm_brlw0 { border-left-width: 0px; }
  .lm_brrw0 { border-right-width: 0px; }
  .lm_brbw0 { border-bottom-width: 0px; }
  .lm_brw1 { border-width: 1px; }
  .lm_brtw1 { border-top-width: 1px; }
  .lm_brlw1 { border-left-width: 1px; }
  .lm_brrw1 { border-right-width: 1px; }
  .lm_brbw1 { border-bottom-width: 1px; }

  /* Border style */
  .lm_brstn { border-style: none; }
  .lm_brsts { border-style: solid; }
  .lm_brstd { border-style: dashed; }


  /* Display */
  /* ======= */
  .lm_dn { display: none; }
  .lm_dn_i { display: none !important; }
  .lm_db { display: block; }
  .lm_di { display: inline; }
  .lm_dib { display: inline-block; }
  .lm_dt { display: table; }
  .lm_dtr { display: table-row; }
  .lm_dtc { display: table-cell; }
  .lm_du { display: unset; }

  /* Flex */
  .lm_dfdr { display: flex; flex-direction: row; }
  .lm_dfdrr { display: flex; flex-direction: row-reverse; }
  .lm_dfdc { display: flex; flex-direction: column; }
  .lm_dfdcr { display: flex; flex-direction: column-reverse; }
  .lm_difdr { display: inline-flex; flex-direction: row; }
  .lm_difdrr { display: inline-flex; flex-direction: row-reverse; }
  .lm_difdc { display: inline-flex; flex-direction: column; }
  .lm_difdcr { display: inline-flex; flex-direction: column-reverse; }
  .lm_fwn { flex-wrap: nowrap; }
  .lm_fww { flex-wrap: wrap; }
  .lm_fwwr { flex-wrap: wrap-reverse; }
  .lm_fjcs { justify-content: flex-start; }
  .lm_fjce { justify-content: flex-end; }
  .lm_fjcc { justify-content: center; }
  .lm_fjcsa { justify-content: space-around; }
  .lm_fjcsb { justify-content: space-between; }
  .lm_fais { align-items: flex-start; }
  .lm_faie { align-items: flex-end; }
  .lm_faic { align-items: center; }
  .lm_faib { align-items: baseline; }
  .lm_faist { align-items: stretch; }
  .lm_facs { align-content: flex-start; }
  .lm_face { align-content: flex-end; }
  .lm_facc { align-content: center; }
  .lm_facsa { align-content: space-around; }
  .lm_facsb { align-content: space-between; }
  .lm_facst { align-content: stretch; }
  .lm_fass { align-self: flex-start; }
  .lm_fase { align-self: flex-end; }
  .lm_fasc { align-self: center; }
  .lm_fasb { align-self: baseline; }
  .lm_fasst { align-self: stretch; }
  .lm_fg0 { flex-grow: 0; }
  .lm_fg1 { flex-grow: 1; }
  .lm_fsh0 { flex-shrink: 0; }
  .lm_fsh1 { flex-shrink: 1; }


  /* Height */
  /* ====== */
  .lm_h0 { height: 0; }
  .lm_h100p, .lm_hfull { height: 100%; }
  .lm_hauto { height: auto; }


  /* Width */
  /* ===== */
  .lm_w0 { width: 0; }
  .lm_w1 { width: 1px; }
  .lm_w5p { width: 5%; }
  .lm_w7p { width: 7%; }
  .lm_w10p { width: 10%; }
  .lm_w12p { width: 12.5%; }
  .lm_w15p { width: 15%; }
  .lm_w20p { width: 20%; }
  .lm_w25p { width: 25%; }
  .lm_w33p { width: 33.33333333%; }
  .lm_w35p { width: 35%; }
  .lm_w40p { width: 40%; }
  .lm_w50p { width: 50%; }
  .lm_w60p { width: 60%; }
  .lm_w65p { width: 65%; }
  .lm_w66p { width: 66.66666666%; }
  .lm_w70p { width: 70%; }
  .lm_w75p { width: 75%; }
  .lm_w80p { width: 80%; }
  .lm_w85p { width: 85%; }
  .lm_w88p { width: 87.5%; }
  .lm_w90p { width: 90%; }
  .lm_w93p { width: 93%; }
  .lm_w95p { width: 95%; }
  .lm_w100p, .lm_wfull { width: 100%; }
  .lm_wauto { width: auto; }


  /* Margins */
  /* ======= */
  .lm_m0auto, .lm_m0a { margin: 0 auto; }
  .lm_m0auto_i, .lm_m0a_i { margin: 0 auto !important; }
  .lm_mauto0, .lm_ma0 { margin: auto 0; }
  .lm_mauto0_i, .lm_ma0_i { margin: auto 0 !important; }
  .lm_mauto, .lm_ma { margin: auto; }
  .lm_mauto_i, .lm_ma_i { margin: auto !important; }
  .lm_mtauto, .lm_mta { margin-top: auto; }
  .lm_mtauto_i, .lm_mta_i { margin-top: auto !important; }
  .lm_mlauto, .lm_mla { margin-left: auto; }
  .lm_mlauto_i, .lm_mla_i { margin-left: auto !important; }
  .lm_mrauto, .lm_mra { margin-right: auto; }
  .lm_mrauto_i, .lm_mra_i { margin-right: auto !important; }
  .lm_mbauto, .lm_mba { margin-bottom: auto; }
  .lm_mbauto_i, .lm_mba_i { margin-bottom: auto !important; }
  .lm_m0 { margin: 0; }
  .lm_m0_i { margin: 0 !important; }
  .lm_mt0 { margin-top: 0; }
  .lm_mt0_i { margin-top: 0 !important; }
  .lm_ml0 { margin-left: 0; }
  .lm_ml0_i { margin-left: 0 !important; }
  .lm_mr0 { margin-right: 0; }
  .lm_mr0_i { margin-right: 0 !important; }
  .lm_mb0 { margin-bottom: 0; }
  .lm_mb0_i { margin-bottom: 0 !important; }
  .lm_m1 { margin: 1px; }
  .lm_mt1 { margin-top: 1px; }
  .lm_ml1 { margin-left: 1px; }
  .lm_mr1 { margin-right: 1px; }
  .lm_mb1 { margin-bottom: 1px; }
  .lm_m2 { margin: 2px; }
  .lm_mt2 { margin-top: 2px; }
  .lm_ml2 { margin-left: 2px; }
  .lm_mr2 { margin-right: 2px; }
  .lm_mb2 { margin-bottom: 2px; }
  .lm_m3 { margin: 3px; }
  .lm_mt3 { margin-top: 3px; }
  .lm_ml3 { margin-left: 3px; }
  .lm_mr3 { margin-right: 3px; }
  .lm_mb3 { margin-bottom: 3px; }
  .lm_m4 { margin: 4px; }
  .lm_mt4 { margin-top: 4px; }
  .lm_ml4 { margin-left: 4px; }
  .lm_mr4 { margin-right: 4px; }
  .lm_mb4 { margin-bottom: 4px; }
  .lm_m5 { margin: 5px; }
  .lm_mt5 { margin-top: 5px; }
  .lm_ml5 { margin-left: 5px; }
  .lm_mr5 { margin-right: 5px; }
  .lm_mb5 { margin-bottom: 5px; }
  .lm_m7 { margin: 7px; }
  .lm_mt7 { margin-top: 7px; }
  .lm_ml7 { margin-left: 7px; }
  .lm_mr7 { margin-right: 7px; }
  .lm_mb7 { margin-bottom: 7px; }
  .lm_m10 { margin: 10px; }
  .lm_mt10 { margin-top: 10px; }
  .lm_ml10 { margin-left: 10px; }
  .lm_mr10 { margin-right: 10px; }
  .lm_mb10 { margin-bottom: 10px; }
  .lm_m13 { margin: 13px; }
  .lm_mt13 { margin-top: 13px; }
  .lm_ml13 { margin-left: 13px; }
  .lm_mr13 { margin-right: 13px; }
  .lm_mb13 { margin-bottom: 13px; }
  .lm_m15 { margin: 15px; }
  .lm_mt15 { margin-top: 15px; }
  .lm_ml15 { margin-left: 15px; }
  .lm_mr15 { margin-right: 15px; }
  .lm_mb15 { margin-bottom: 15px; }
  .lm_m20 { margin: 20px; }
  .lm_mt20 { margin-top: 20px; }
  .lm_ml20 { margin-left: 20px; }
  .lm_mr20 { margin-right: 20px; }
  .lm_mb20 { margin-bottom: 20px; }
  .lm_m25 { margin: 25px; }
  .lm_mt25 { margin-top: 25px; }
  .lm_ml25 { margin-left: 25px; }
  .lm_mr25 { margin-right: 25px; }
  .lm_mb25 { margin-bottom: 25px; }
  .lm_m30 { margin: 30px; }
  .lm_mt30 { margin-top: 30px; }
  .lm_ml30 { margin-left: 30px; }
  .lm_mr30 { margin-right: 30px; }
  .lm_mb30 { margin-bottom: 30px; }
  .lm_m35 { margin: 35px; }
  .lm_mt35 { margin-top: 35px; }
  .lm_ml35 { margin-left: 35px; }
  .lm_mr35 { margin-right: 35px; }
  .lm_mb35 { margin-bottom: 35px; }
  .lm_m-1 { margin: -1px; }
  .lm_mt-1 { margin-top: -1px; }
  .lm_ml-1 { margin-left: -1px; }
  .lm_mr-1 { margin-right: -1px; }
  .lm_mb-1 { margin-bottom: -1px; }
  .lm_m-2 { margin: -2px; }
  .lm_mt-2 { margin-top: -2px; }
  .lm_ml-2 { margin-left: -2px; }
  .lm_mr-2 { margin-right: -2px; }
  .lm_mb-2 { margin-bottom: -2px; }
  .lm_m-3 { margin: -3px; }
  .lm_mt-3 { margin-top: -3px; }
  .lm_ml-3 { margin-left: -3px; }
  .lm_mr-3 { margin-right: -3px; }
  .lm_mb-3 { margin-bottom: -3px; }
  .lm_m-4 { margin: -4px; }
  .lm_mt-4 { margin-top: -4px; }
  .lm_ml-4 { margin-left: -4px; }
  .lm_mr-4 { margin-right: -4px; }
  .lm_mb-4 { margin-bottom: -4px; }
  .lm_m-5 { margin: -5px; }
  .lm_mt-5 { margin-top: -5px; }
  .lm_ml-5 { margin-left: -5px; }
  .lm_mr-5 { margin-right: -5px; }
  .lm_mb-5 { margin-bottom: -5px; }
  .lm_m-7 { margin: -7px; }
  .lm_mt-7 { margin-top: -7px; }
  .lm_ml-7 { margin-left: -7px; }
  .lm_mr-7 { margin-right: -7px; }
  .lm_mb-7 { margin-bottom: -7px; }
  .lm_m-10 { margin: -10px; }
  .lm_mt-10 { margin-top: -10px; }
  .lm_ml-10 { margin-left: -10px; }
  .lm_mr-10 { margin-right: -10px; }
  .lm_mb-10 { margin-bottom: -10px; }
  .lm_m-13 { margin: -13px; }
  .lm_mt-13 { margin-top: -13px; }
  .lm_ml-13 { margin-left: -13px; }
  .lm_mr-13 { margin-right: -13px; }
  .lm_mb-13 { margin-bottom: -13px; }
  .lm_m-15 { margin: -15px; }
  .lm_mt-15 { margin-top: -15px; }
  .lm_ml-15 { margin-left: -15px; }
  .lm_mr-15 { margin-right: -15px; }
  .lm_mb-15 { margin-bottom: -15px; }
  .lm_m-20 { margin: -20px; }
  .lm_mt-20 { margin-top: -20px; }
  .lm_ml-20 { margin-left: -20px; }
  .lm_mr-20 { margin-right: -20px; }
  .lm_mb-20 { margin-bottom: -20px; }
  .lm_m-25 { margin: -25px; }
  .lm_mt-25 { margin-top: -25px; }
  .lm_ml-25 { margin-left: -25px; }
  .lm_mr-25 { margin-right: -25px; }
  .lm_mb-25 { margin-bottom: -25px; }
  .lm_m-30 { margin: -30px; }
  .lm_mt-30 { margin-top: -30px; }
  .lm_ml-30 { margin-left: -30px; }
  .lm_mr-30 { margin-right: -30px; }
  .lm_mb-30 { margin-bottom: -30px; }
  .lm_m-35 { margin: -35px; }
  .lm_mt-35 { margin-top: -35px; }
  .lm_ml-35 { margin-left: -35px; }
  .lm_mr-35 { margin-right: -35px; }
  .lm_mb-35 { margin-bottom: -35px; }


  /* Paddings */
  /* ======== */
  .lm_p0 { padding: 0 !important; }
  .lm_pt0 { padding-top: 0 !important; }
  .lm_pl0 { padding-left: 0 !important; }
  .lm_pr0 { padding-right: 0 !important; }
  .lm_pb0 { padding-bottom: 0 !important; }
  .lm_p1 { padding: 1px !important; }
  .lm_pt1 { padding-top: 1px !important; }
  .lm_pl1 { padding-left: 1px !important; }
  .lm_pr1 { padding-right: 1px !important; }
  .lm_pb1 { padding-bottom: 1px !important; }
  .lm_p2 { padding: 2px !important; }
  .lm_pt2 { padding-top: 2px !important; }
  .lm_pl2 { padding-left: 2px !important; }
  .lm_pr2 { padding-right: 2px !important; }
  .lm_pb2 { padding-bottom: 2px !important; }
  .lm_p3 { padding: 3px !important; }
  .lm_pt3 { padding-top: 3px !important; }
  .lm_pl3 { padding-left: 3px !important; }
  .lm_pr3 { padding-right: 3px !important; }
  .lm_pb3 { padding-bottom: 3px !important; }
  .lm_p4 { padding: 4px !important; }
  .lm_pt4 { padding-top: 4px !important; }
  .lm_pl4 { padding-left: 4px !important; }
  .lm_pr4 { padding-right: 4px !important; }
  .lm_pb4 { padding-bottom: 4px !important; }
  .lm_p5 { padding: 5px !important; }
  .lm_pt5 { padding-top: 5px !important; }
  .lm_pl5 { padding-left: 5px !important; }
  .lm_pr5 { padding-right: 5px !important; }
  .lm_pb5 { padding-bottom: 5px !important; }
  .lm_p7 { padding: 7px !important; }
  .lm_pt7 { padding-top: 7px !important; }
  .lm_pl7 { padding-left: 7px !important; }
  .lm_pr7 { padding-right: 7px !important; }
  .lm_pb7 { padding-bottom: 7px !important; }
  .lm_p10 { padding: 10px !important; }
  .lm_pt10 { padding-top: 10px !important; }
  .lm_pl10 { padding-left: 10px !important; }
  .lm_pr10 { padding-right: 10px !important; }
  .lm_pb10 { padding-bottom: 10px !important; }
  .lm_p13 { padding: 13px !important; }
  .lm_pt13 { padding-top: 13px !important; }
  .lm_pl13 { padding-left: 13px !important; }
  .lm_pr13 { padding-right: 13px !important; }
  .lm_pb13 { padding-bottom: 13px !important; }
  .lm_p15 { padding: 15px !important; }
  .lm_pt15 { padding-top: 15px !important; }
  .lm_pl15 { padding-left: 15px !important; }
  .lm_pr15 { padding-right: 15px !important; }
  .lm_pb15 { padding-bottom: 15px !important; }
  .lm_p20 { padding: 20px !important; }
  .lm_pt20 { padding-top: 20px !important; }
  .lm_pl20 { padding-left: 20px !important; }
  .lm_pr20 { padding-right: 20px !important; }
  .lm_pb20 { padding-bottom: 20px !important; }
  .lm_p25 { padding: 25px !important; }
  .lm_pt25 { padding-top: 25px !important; }
  .lm_pl25 { padding-left: 25px !important; }
  .lm_pr25 { padding-right: 25px !important; }
  .lm_pb25 { padding-bottom: 25px !important; }
  .lm_p30 { padding: 30px !important; }
  .lm_pt30 { padding-top: 30px !important; }
  .lm_pl30 { padding-left: 30px !important; }
  .lm_pr30 { padding-right: 30px !important; }
  .lm_pb30 { padding-bottom: 30px !important; }
  .lm_p35 { padding: 35px !important; }
  .lm_pt35 { padding-top: 35px !important; }
  .lm_pl35 { padding-left: 35px !important; }
  .lm_pr35 { padding-right: 35px !important; }
  .lm_pb35 { padding-bottom: 35px !important; }

  .lm_pt10p { padding-top: 10% !important; }
  .lm_pt15p { padding-top: 15% !important; }
  .lm_pt20p { padding-top: 20% !important; }
  .lm_pt25p { padding-top: 25% !important; }
  .lm_pt33p { padding-top: 33.33333333% !important; }
  .lm_pt35p { padding-top: 35% !important; }
  .lm_pt40p { padding-top: 40% !important; }
  .lm_pt50p { padding-top: 50% !important; }
  .lm_pt60p { padding-top: 60% !important; }
  .lm_pt65p { padding-top: 65% !important; }
  .lm_pt66p { padding-top: 66.66666666% !important; }
  .lm_pt70p { padding-top: 70% !important; }
  .lm_pt75p { padding-top: 75% !important; }
  .lm_pt80p { padding-top: 80% !important; }
  .lm_pt85p { padding-top: 85% !important; }
  .lm_pt90p { padding-top: 90% !important; }
  .lm_pt100p { padding-top: 100% !important; }


  /* Aligning */
  /* ======== */
  /* Vertical align */
  .lm_vat { vertical-align: top; }
  .lm_vam { vertical-align: middle; }
  .lm_vab { vertical-align: bottom; }
  .lm_vabl { vertical-align: baseline; }

  /* Horizontal text align */
  .lm_tal { text-align: left; }
  .lm_tal_i { text-align: left !important; }
  .lm_tac { text-align: center; }
  .lm_tac_i { text-align: center !important; }
  .lm_tar { text-align: right; }
  .lm_tar_i { text-align: right !important; }
  .lm_taj { text-align: justify; }
  .lm_taj_i { text-align: justify !important; }


  /* Text styling */
  /* ============ */
  /* Font style*/
  .lm_fsi { font-style: italic; }

  /* Font size */
  .lm_fz0 { font-size: 0; }
  .lm_fz10, .lm_fz10_a, .lm_fz10_a * { font-size: 10px; }
  .lm_fz11, .lm_fz11_a, .lm_fz11_a * { font-size: 11px; }
  .lm_fz12, .lm_fz12_a, .lm_fz12_a * { font-size: 12px; }
  .lm_fz13, .lm_fz13_a, .lm_fz13_a * { font-size: 13px; }
  .lm_fz14, .lm_fz14_a, .lm_fz14_a * { font-size: 14px; }
  .lm_fz15 { font-size: 15px; }
  .lm_fz16 { font-size: 16px; }
  .lm_fz18 { font-size: 18px; }
  .lm_fz20 { font-size: 20px; }
  .lm_fz22 { font-size: 22px; }
  .lm_fz24 { font-size: 24px; }
  .lm_fz26 { font-size: 26px; }
  .lm_fz28 { font-size: 28px; }
  .lm_fz30 { font-size: 30px; }
  .lm_fz32 { font-size: 32px; }
  .lm_fz34 { font-size: 34px; }
  .lm_fz36 { font-size: 36px; }
  .lm_fz38 { font-size: 38px; }
  .lm_fz40 { font-size: 40px; }
  .lm_fz42 { font-size: 42px; }
  .lm_fz44 { font-size: 44px; }
  .lm_fz46 { font-size: 46px; }
  .lm_fz48 { font-size: 48px; }
  .lm_fz50 { font-size: 50px; }
  .lm_fz0_i { font-size: 0 !important; }
  .lm_fz10_i, .lm_fz10_a_i, .lm_fz10_a_i * { font-size: 10px !important; }
  .lm_fz11_i, .lm_fz11_a_i, .lm_fz11_a_i * { font-size: 11px !important; }
  .lm_fz12_i, .lm_fz12_a_i, .lm_fz12_a_i * { font-size: 12px !important; }
  .lm_fz13_i, .lm_fz13_a_i, .lm_fz13_a_i * { font-size: 13px !important; }
  .lm_fz14_i, .lm_fz14_a_i, .lm_fz14_a_i * { font-size: 14px !important; }
  .lm_fz15_i { font-size: 15px !important; }
  .lm_fz16_i { font-size: 16px !important; }
  .lm_fz18_i { font-size: 18px !important; }
  .lm_fz20_i { font-size: 20px !important; }
  .lm_fz22_i { font-size: 22px !important; }
  .lm_fz24_i { font-size: 24px !important; }
  .lm_fz26_i { font-size: 26px !important; }
  .lm_fz28_i { font-size: 28px !important; }
  .lm_fz30_i { font-size: 30px !important; }
  .lm_fz32_i { font-size: 32px !important; }
  .lm_fz34_i { font-size: 34px !important; }
  .lm_fz36_i { font-size: 36px !important; }
  .lm_fz38_i { font-size: 38px !important; }
  .lm_fz40_i { font-size: 40px !important; }
  .lm_fz42_i { font-size: 42px !important; }
  .lm_fz44_i { font-size: 44px !important; }
  .lm_fz46_i { font-size: 46px !important; }
  .lm_fz48_i { font-size: 48px !important; }
  .lm_fz50_i { font-size: 50px !important; }

  /* Font weight */
  .lm_fw900, .lm_fw900_a, .lm_fw900_a * { font-family: 'MuseoSansCyrl-900', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 900; }
  .lm_fw900_i, .lm_fw900_a_i, .lm_fw900_a_i * { font-family: 'MuseoSansCyrl-900', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 900 !important; }
  .lm_fw700, .lm_fw700_a, .lm_fw700_a *, .bold, .bold_a, .bold_a *, .semibold, .semibold_a, .semibold_a * { font-family: 'MuseoSansCyrl-700', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 700; }
  .lm_fw700_i, .lm_fw700_a_i, .lm_fw700_a_i *, .bold_i, .bold_a_i, .bold_a_i *, .semibold_i, .semibold_a_i, .semibold_a_i * { font-family: 'MuseoSansCyrl-700', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 700 !important; }
  .lm_fw400, .lm_fw400_a, .lm_fw400_a * { font-family: 'MuseoSansCyrl-500', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 400; }
  .lm_fw400_i, .lm_fw400_a_i, .lm_fw400_a_i * { font-family: 'MuseoSansCyrl-500', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 400 !important; }
  .lm_fw300, .lm_fw300_a, .lm_fw300_a *, .semilight, .semilight_a, .semilight_a * { font-family: 'MuseoSansCyrl-300', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 300; }
  .lm_fw300_i, .lm_fw300_a_i, .lm_fw300_a_i *, .semilight_i, .semilight_a_i, .semilight_a_i * { font-family: 'MuseoSansCyrl-300', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 300 !important; }

  /* Line height */
  .lm_lh10 { line-height: 1; }
  .lm_lh100p { line-height: 100%; }
  .lm_lh11 { line-height: 1.1; }
  .lm_lh110p { line-height: 110%; }
  .lm_lh12 { line-height: 1.2; }
  .lm_lh120p { line-height: 120%; }
  .lm_lh13 { line-height: 1.3; }
  .lm_lh130p { line-height: 130%; }
  .lm_lh14 { line-height: 1.4; }
  .lm_lh140p { line-height: 140%; }
  .lm_lh15 { line-height: 1.5; }
  .lm_lh150p { line-height: 150%; }
  .lm_lh16 { line-height: 1.6; }
  .lm_lh160p { line-height: 160%; }
  .lm_lh17 { line-height: 1.7; }
  .lm_lh170p { line-height: 170%; }
  .lm_lh18 { line-height: 1.8; }
  .lm_lh180p { line-height: 180%; }
  .lm_lh19 { line-height: 1.9; }
  .lm_lh190p { line-height: 190%; }
  .lm_lh20 { line-height: 2; }
  .lm_lh200p { line-height: 200%; }


  /* Floating */
  /* ======== */
  .lm_fln { float: none; }
  .lm_fll { float: left; }
  .lm_flr { float: right; }
}
@media screen and (max-width: 767px) {
  .vitrine-item-text .subsubheader {
    font-size: 18px;
  }



  .price-table-head {
    font-size: 18px;
  }



  .contact-info-container > div {
    width: 100%;
  }
}

/* large portrait mobile - lpm */
@media screen and (max-width: 699px) {
  /* Border */
  /* ====== */
  /* Border width */
  .lpm_brw0 { border-width: 0px; }
  .lpm_brtw0 { border-top-width: 0px; }
  .lpm_brlw0 { border-left-width: 0px; }
  .lpm_brrw0 { border-right-width: 0px; }
  .lpm_brbw0 { border-bottom-width: 0px; }
  .lpm_brw1 { border-width: 1px; }
  .lpm_brtw1 { border-top-width: 1px; }
  .lpm_brlw1 { border-left-width: 1px; }
  .lpm_brrw1 { border-right-width: 1px; }
  .lpm_brbw1 { border-bottom-width: 1px; }

  /* Border style */
  .lpm_brstn { border-style: none; }
  .lpm_brsts { border-style: solid; }
  .lpm_brstd { border-style: dashed; }


  /* Display */
  /* ======= */
  .lpm_dn { display: none; }
  .lpm_dn_i { display: none !important; }
  .lpm_db { display: block; }
  .lpm_di { display: inline; }
  .lpm_dib { display: inline-block; }
  .lpm_dt { display: table; }
  .lpm_dtr { display: table-row; }
  .lpm_dtc { display: table-cell; }
  .lpm_du { display: unset; }

  /* Flex */
  .lpm_dfdr { display: flex; flex-direction: row; }
  .lpm_dfdrr { display: flex; flex-direction: row-reverse; }
  .lpm_dfdc { display: flex; flex-direction: column; }
  .lpm_dfdcr { display: flex; flex-direction: column-reverse; }
  .lpm_difdr { display: inline-flex; flex-direction: row; }
  .lpm_difdrr { display: inline-flex; flex-direction: row-reverse; }
  .lpm_difdc { display: inline-flex; flex-direction: column; }
  .lpm_difdcr { display: inline-flex; flex-direction: column-reverse; }
  .lpm_fwn { flex-wrap: nowrap; }
  .lpm_fww { flex-wrap: wrap; }
  .lpm_fwwr { flex-wrap: wrap-reverse; }
  .lpm_fjcs { justify-content: flex-start; }
  .lpm_fjce { justify-content: flex-end; }
  .lpm_fjcc { justify-content: center; }
  .lpm_fjcsa { justify-content: space-around; }
  .lpm_fjcsb { justify-content: space-between; }
  .lpm_fais { align-items: flex-start; }
  .lpm_faie { align-items: flex-end; }
  .lpm_faic { align-items: center; }
  .lpm_faib { align-items: baseline; }
  .lpm_faist { align-items: stretch; }
  .lpm_facs { align-content: flex-start; }
  .lpm_face { align-content: flex-end; }
  .lpm_facc { align-content: center; }
  .lpm_facsa { align-content: space-around; }
  .lpm_facsb { align-content: space-between; }
  .lpm_facst { align-content: stretch; }
  .lpm_fass { align-self: flex-start; }
  .lpm_fase { align-self: flex-end; }
  .lpm_fasc { align-self: center; }
  .lpm_fasb { align-self: baseline; }
  .lpm_fasst { align-self: stretch; }
  .lpm_fg0 { flex-grow: 0; }
  .lpm_fg1 { flex-grow: 1; }
  .lpm_fsh0 { flex-shrink: 0; }
  .lpm_fsh1 { flex-shrink: 1; }


  /* Height */
  /* ====== */
  .lpm_h0 { height: 0; }
  .lpm_h100p, .lpm_hfull { height: 100%; }
  .lpm_hauto { height: auto; }


  /* Width */
  /* ===== */
  .lpm_w0 { width: 0; }
  .lpm_w1 { width: 1px; }
  .lpm_w5p { width: 5%; }
  .lpm_w7p { width: 7%; }
  .lpm_w10p { width: 10%; }
  .lpm_w12p { width: 12.5%; }
  .lpm_w15p { width: 15%; }
  .lpm_w20p { width: 20%; }
  .lpm_w25p { width: 25%; }
  .lpm_w33p { width: 33.33333333%; }
  .lpm_w35p { width: 35%; }
  .lpm_w40p { width: 40%; }
  .lpm_w50p { width: 50%; }
  .lpm_w60p { width: 60%; }
  .lpm_w65p { width: 65%; }
  .lpm_w66p { width: 66.66666666%; }
  .lpm_w70p { width: 70%; }
  .lpm_w75p { width: 75%; }
  .lpm_w80p { width: 80%; }
  .lpm_w85p { width: 85%; }
  .lpm_w88p { width: 87.5%; }
  .lpm_w90p { width: 90%; }
  .lpm_w93p { width: 93%; }
  .lpm_w95p { width: 95%; }
  .lpm_w100p, .lpm_wfull { width: 100%; }
  .lpm_wauto { width: auto; }


  /* Margins */
  /* ======= */
  .lpm_m0auto, .lpm_m0a { margin: 0 auto; }
  .lpm_m0auto_i, .lpm_m0a_i { margin: 0 auto !important; }
  .lpm_mauto0, .lpm_ma0 { margin: auto 0; }
  .lpm_mauto0_i, .lpm_ma0_i { margin: auto 0 !important; }
  .lpm_mauto, .lpm_ma { margin: auto; }
  .lpm_mauto_i, .lpm_ma_i { margin: auto !important; }
  .lpm_mtauto, .lpm_mta { margin-top: auto; }
  .lpm_mtauto_i, .lpm_mta_i { margin-top: auto !important; }
  .lpm_mlauto, .lpm_mla { margin-left: auto; }
  .lpm_mlauto_i, .lpm_mla_i { margin-left: auto !important; }
  .lpm_mrauto, .lpm_mra { margin-right: auto; }
  .lpm_mrauto_i, .lpm_mra_i { margin-right: auto !important; }
  .lpm_mbauto, .lpm_mba { margin-bottom: auto; }
  .lpm_mbauto_i, .lpm_mba_i { margin-bottom: auto !important; }
  .lpm_m0 { margin: 0; }
  .lpm_m0_i { margin: 0 !important; }
  .lpm_mt0 { margin-top: 0; }
  .lpm_mt0_i { margin-top: 0 !important; }
  .lpm_ml0 { margin-left: 0; }
  .lpm_ml0_i { margin-left: 0 !important; }
  .lpm_mr0 { margin-right: 0; }
  .lpm_mr0_i { margin-right: 0 !important; }
  .lpm_mb0 { margin-bottom: 0; }
  .lpm_mb0_i { margin-bottom: 0 !important; }
  .lpm_m1 { margin: 1px; }
  .lpm_mt1 { margin-top: 1px; }
  .lpm_ml1 { margin-left: 1px; }
  .lpm_mr1 { margin-right: 1px; }
  .lpm_mb1 { margin-bottom: 1px; }
  .lpm_m2 { margin: 2px; }
  .lpm_mt2 { margin-top: 2px; }
  .lpm_ml2 { margin-left: 2px; }
  .lpm_mr2 { margin-right: 2px; }
  .lpm_mb2 { margin-bottom: 2px; }
  .lpm_m3 { margin: 3px; }
  .lpm_mt3 { margin-top: 3px; }
  .lpm_ml3 { margin-left: 3px; }
  .lpm_mr3 { margin-right: 3px; }
  .lpm_mb3 { margin-bottom: 3px; }
  .lpm_m4 { margin: 4px; }
  .lpm_mt4 { margin-top: 4px; }
  .lpm_ml4 { margin-left: 4px; }
  .lpm_mr4 { margin-right: 4px; }
  .lpm_mb4 { margin-bottom: 4px; }
  .lpm_m5 { margin: 5px; }
  .lpm_mt5 { margin-top: 5px; }
  .lpm_ml5 { margin-left: 5px; }
  .lpm_mr5 { margin-right: 5px; }
  .lpm_mb5 { margin-bottom: 5px; }
  .lpm_m7 { margin: 7px; }
  .lpm_mt7 { margin-top: 7px; }
  .lpm_ml7 { margin-left: 7px; }
  .lpm_mr7 { margin-right: 7px; }
  .lpm_mb7 { margin-bottom: 7px; }
  .lpm_m10 { margin: 10px; }
  .lpm_mt10 { margin-top: 10px; }
  .lpm_ml10 { margin-left: 10px; }
  .lpm_mr10 { margin-right: 10px; }
  .lpm_mb10 { margin-bottom: 10px; }
  .lpm_m13 { margin: 13px; }
  .lpm_mt13 { margin-top: 13px; }
  .lpm_ml13 { margin-left: 13px; }
  .lpm_mr13 { margin-right: 13px; }
  .lpm_mb13 { margin-bottom: 13px; }
  .lpm_m15 { margin: 15px; }
  .lpm_mt15 { margin-top: 15px; }
  .lpm_ml15 { margin-left: 15px; }
  .lpm_mr15 { margin-right: 15px; }
  .lpm_mb15 { margin-bottom: 15px; }
  .lpm_m20 { margin: 20px; }
  .lpm_mt20 { margin-top: 20px; }
  .lpm_ml20 { margin-left: 20px; }
  .lpm_mr20 { margin-right: 20px; }
  .lpm_mb20 { margin-bottom: 20px; }
  .lpm_m25 { margin: 25px; }
  .lpm_mt25 { margin-top: 25px; }
  .lpm_ml25 { margin-left: 25px; }
  .lpm_mr25 { margin-right: 25px; }
  .lpm_mb25 { margin-bottom: 25px; }
  .lpm_m30 { margin: 30px; }
  .lpm_mt30 { margin-top: 30px; }
  .lpm_ml30 { margin-left: 30px; }
  .lpm_mr30 { margin-right: 30px; }
  .lpm_mb30 { margin-bottom: 30px; }
  .lpm_m35 { margin: 35px; }
  .lpm_mt35 { margin-top: 35px; }
  .lpm_ml35 { margin-left: 35px; }
  .lpm_mr35 { margin-right: 35px; }
  .lpm_mb35 { margin-bottom: 35px; }
  .lpm_m-1 { margin: -1px; }
  .lpm_mt-1 { margin-top: -1px; }
  .lpm_ml-1 { margin-left: -1px; }
  .lpm_mr-1 { margin-right: -1px; }
  .lpm_mb-1 { margin-bottom: -1px; }
  .lpm_m-2 { margin: -2px; }
  .lpm_mt-2 { margin-top: -2px; }
  .lpm_ml-2 { margin-left: -2px; }
  .lpm_mr-2 { margin-right: -2px; }
  .lpm_mb-2 { margin-bottom: -2px; }
  .lpm_m-3 { margin: -3px; }
  .lpm_mt-3 { margin-top: -3px; }
  .lpm_ml-3 { margin-left: -3px; }
  .lpm_mr-3 { margin-right: -3px; }
  .lpm_mb-3 { margin-bottom: -3px; }
  .lpm_m-4 { margin: -4px; }
  .lpm_mt-4 { margin-top: -4px; }
  .lpm_ml-4 { margin-left: -4px; }
  .lpm_mr-4 { margin-right: -4px; }
  .lpm_mb-4 { margin-bottom: -4px; }
  .lpm_m-5 { margin: -5px; }
  .lpm_mt-5 { margin-top: -5px; }
  .lpm_ml-5 { margin-left: -5px; }
  .lpm_mr-5 { margin-right: -5px; }
  .lpm_mb-5 { margin-bottom: -5px; }
  .lpm_m-7 { margin: -7px; }
  .lpm_mt-7 { margin-top: -7px; }
  .lpm_ml-7 { margin-left: -7px; }
  .lpm_mr-7 { margin-right: -7px; }
  .lpm_mb-7 { margin-bottom: -7px; }
  .lpm_m-10 { margin: -10px; }
  .lpm_mt-10 { margin-top: -10px; }
  .lpm_ml-10 { margin-left: -10px; }
  .lpm_mr-10 { margin-right: -10px; }
  .lpm_mb-10 { margin-bottom: -10px; }
  .lpm_m-13 { margin: -13px; }
  .lpm_mt-13 { margin-top: -13px; }
  .lpm_ml-13 { margin-left: -13px; }
  .lpm_mr-13 { margin-right: -13px; }
  .lpm_mb-13 { margin-bottom: -13px; }
  .lpm_m-15 { margin: -15px; }
  .lpm_mt-15 { margin-top: -15px; }
  .lpm_ml-15 { margin-left: -15px; }
  .lpm_mr-15 { margin-right: -15px; }
  .lpm_mb-15 { margin-bottom: -15px; }
  .lpm_m-20 { margin: -20px; }
  .lpm_mt-20 { margin-top: -20px; }
  .lpm_ml-20 { margin-left: -20px; }
  .lpm_mr-20 { margin-right: -20px; }
  .lpm_mb-20 { margin-bottom: -20px; }
  .lpm_m-25 { margin: -25px; }
  .lpm_mt-25 { margin-top: -25px; }
  .lpm_ml-25 { margin-left: -25px; }
  .lpm_mr-25 { margin-right: -25px; }
  .lpm_mb-25 { margin-bottom: -25px; }
  .lpm_m-30 { margin: -30px; }
  .lpm_mt-30 { margin-top: -30px; }
  .lpm_ml-30 { margin-left: -30px; }
  .lpm_mr-30 { margin-right: -30px; }
  .lpm_mb-30 { margin-bottom: -30px; }
  .lpm_m-35 { margin: -35px; }
  .lpm_mt-35 { margin-top: -35px; }
  .lpm_ml-35 { margin-left: -35px; }
  .lpm_mr-35 { margin-right: -35px; }
  .lpm_mb-35 { margin-bottom: -35px; }


  /* Paddings */
  /* ======== */
  .lpm_p0 { padding: 0 !important; }
  .lpm_pt0 { padding-top: 0 !important; }
  .lpm_pl0 { padding-left: 0 !important; }
  .lpm_pr0 { padding-right: 0 !important; }
  .lpm_pb0 { padding-bottom: 0 !important; }
  .lpm_p1 { padding: 1px !important; }
  .lpm_pt1 { padding-top: 1px !important; }
  .lpm_pl1 { padding-left: 1px !important; }
  .lpm_pr1 { padding-right: 1px !important; }
  .lpm_pb1 { padding-bottom: 1px !important; }
  .lpm_p2 { padding: 2px !important; }
  .lpm_pt2 { padding-top: 2px !important; }
  .lpm_pl2 { padding-left: 2px !important; }
  .lpm_pr2 { padding-right: 2px !important; }
  .lpm_pb2 { padding-bottom: 2px !important; }
  .lpm_p3 { padding: 3px !important; }
  .lpm_pt3 { padding-top: 3px !important; }
  .lpm_pl3 { padding-left: 3px !important; }
  .lpm_pr3 { padding-right: 3px !important; }
  .lpm_pb3 { padding-bottom: 3px !important; }
  .lpm_p4 { padding: 4px !important; }
  .lpm_pt4 { padding-top: 4px !important; }
  .lpm_pl4 { padding-left: 4px !important; }
  .lpm_pr4 { padding-right: 4px !important; }
  .lpm_pb4 { padding-bottom: 4px !important; }
  .lpm_p5 { padding: 5px !important; }
  .lpm_pt5 { padding-top: 5px !important; }
  .lpm_pl5 { padding-left: 5px !important; }
  .lpm_pr5 { padding-right: 5px !important; }
  .lpm_pb5 { padding-bottom: 5px !important; }
  .lpm_p7 { padding: 7px !important; }
  .lpm_pt7 { padding-top: 7px !important; }
  .lpm_pl7 { padding-left: 7px !important; }
  .lpm_pr7 { padding-right: 7px !important; }
  .lpm_pb7 { padding-bottom: 7px !important; }
  .lpm_p10 { padding: 10px !important; }
  .lpm_pt10 { padding-top: 10px !important; }
  .lpm_pl10 { padding-left: 10px !important; }
  .lpm_pr10 { padding-right: 10px !important; }
  .lpm_pb10 { padding-bottom: 10px !important; }
  .lpm_p13 { padding: 13px !important; }
  .lpm_pt13 { padding-top: 13px !important; }
  .lpm_pl13 { padding-left: 13px !important; }
  .lpm_pr13 { padding-right: 13px !important; }
  .lpm_pb13 { padding-bottom: 13px !important; }
  .lpm_p15 { padding: 15px !important; }
  .lpm_pt15 { padding-top: 15px !important; }
  .lpm_pl15 { padding-left: 15px !important; }
  .lpm_pr15 { padding-right: 15px !important; }
  .lpm_pb15 { padding-bottom: 15px !important; }
  .lpm_p20 { padding: 20px !important; }
  .lpm_pt20 { padding-top: 20px !important; }
  .lpm_pl20 { padding-left: 20px !important; }
  .lpm_pr20 { padding-right: 20px !important; }
  .lpm_pb20 { padding-bottom: 20px !important; }
  .lpm_p25 { padding: 25px !important; }
  .lpm_pt25 { padding-top: 25px !important; }
  .lpm_pl25 { padding-left: 25px !important; }
  .lpm_pr25 { padding-right: 25px !important; }
  .lpm_pb25 { padding-bottom: 25px !important; }
  .lpm_p30 { padding: 30px !important; }
  .lpm_pt30 { padding-top: 30px !important; }
  .lpm_pl30 { padding-left: 30px !important; }
  .lpm_pr30 { padding-right: 30px !important; }
  .lpm_pb30 { padding-bottom: 30px !important; }
  .lpm_p35 { padding: 35px !important; }
  .lpm_pt35 { padding-top: 35px !important; }
  .lpm_pl35 { padding-left: 35px !important; }
  .lpm_pr35 { padding-right: 35px !important; }
  .lpm_pb35 { padding-bottom: 35px !important; }

  .lpm_pt10p { padding-top: 10% !important; }
  .lpm_pt15p { padding-top: 15% !important; }
  .lpm_pt20p { padding-top: 20% !important; }
  .lpm_pt25p { padding-top: 25% !important; }
  .lpm_pt33p { padding-top: 33.33333333% !important; }
  .lpm_pt35p { padding-top: 35% !important; }
  .lpm_pt40p { padding-top: 40% !important; }
  .lpm_pt50p { padding-top: 50% !important; }
  .lpm_pt60p { padding-top: 60% !important; }
  .lpm_pt65p { padding-top: 65% !important; }
  .lpm_pt66p { padding-top: 66.66666666% !important; }
  .lpm_pt70p { padding-top: 70% !important; }
  .lpm_pt75p { padding-top: 75% !important; }
  .lpm_pt80p { padding-top: 80% !important; }
  .lpm_pt85p { padding-top: 85% !important; }
  .lpm_pt90p { padding-top: 90% !important; }
  .lpm_pt100p { padding-top: 100% !important; }


  /* Aligning */
  /* ======== */
  /* Vertical align */
  .lpm_vat { vertical-align: top; }
  .lpm_vam { vertical-align: middle; }
  .lpm_vab { vertical-align: bottom; }
  .lpm_vabl { vertical-align: baseline; }

  /* Horizontal text align */
  .lpm_tal { text-align: left; }
  .lpm_tal_i { text-align: left !important; }
  .lpm_tac { text-align: center; }
  .lpm_tac_i { text-align: center !important; }
  .lpm_tar { text-align: right; }
  .lpm_tar_i { text-align: right !important; }
  .lpm_taj { text-align: justify; }
  .lpm_taj_i { text-align: justify !important; }


  /* Text styling */
  /* ============ */
  /* Font style*/
  .lpm_fsi { font-style: italic; }

  /* Font size */
  .lpm_fz0 { font-size: 0; }
  .lpm_fz10, .lpm_fz10_a, .lpm_fz10_a * { font-size: 10px; }
  .lpm_fz11, .lpm_fz11_a, .lpm_fz11_a * { font-size: 11px; }
  .lpm_fz12, .lpm_fz12_a, .lpm_fz12_a * { font-size: 12px; }
  .lpm_fz13, .lpm_fz13_a, .lpm_fz13_a * { font-size: 13px; }
  .lpm_fz14, .lpm_fz14_a, .lpm_fz14_a * { font-size: 14px; }
  .lpm_fz15 { font-size: 15px; }
  .lpm_fz16 { font-size: 16px; }
  .lpm_fz18 { font-size: 18px; }
  .lpm_fz20 { font-size: 20px; }
  .lpm_fz22 { font-size: 22px; }
  .lpm_fz24 { font-size: 24px; }
  .lpm_fz26 { font-size: 26px; }
  .lpm_fz28 { font-size: 28px; }
  .lpm_fz30 { font-size: 30px; }
  .lpm_fz32 { font-size: 32px; }
  .lpm_fz34 { font-size: 34px; }
  .lpm_fz36 { font-size: 36px; }
  .lpm_fz38 { font-size: 38px; }
  .lpm_fz40 { font-size: 40px; }
  .lpm_fz42 { font-size: 42px; }
  .lpm_fz44 { font-size: 44px; }
  .lpm_fz46 { font-size: 46px; }
  .lpm_fz48 { font-size: 48px; }
  .lpm_fz50 { font-size: 50px; }
  .lpm_fz0_i { font-size: 0 !important; }
  .lpm_fz10_i, .lpm_fz10_a_i, .lpm_fz10_a_i * { font-size: 10px !important; }
  .lpm_fz11_i, .lpm_fz11_a_i, .lpm_fz11_a_i * { font-size: 11px !important; }
  .lpm_fz12_i, .lpm_fz12_a_i, .lpm_fz12_a_i * { font-size: 12px !important; }
  .lpm_fz13_i, .lpm_fz13_a_i, .lpm_fz13_a_i * { font-size: 13px !important; }
  .lpm_fz14_i, .lpm_fz14_a_i, .lpm_fz14_a_i * { font-size: 14px !important; }
  .lpm_fz15_i { font-size: 15px !important; }
  .lpm_fz16_i { font-size: 16px !important; }
  .lpm_fz18_i { font-size: 18px !important; }
  .lpm_fz20_i { font-size: 20px !important; }
  .lpm_fz22_i { font-size: 22px !important; }
  .lpm_fz24_i { font-size: 24px !important; }
  .lpm_fz26_i { font-size: 26px !important; }
  .lpm_fz28_i { font-size: 28px !important; }
  .lpm_fz30_i { font-size: 30px !important; }
  .lpm_fz32_i { font-size: 32px !important; }
  .lpm_fz34_i { font-size: 34px !important; }
  .lpm_fz36_i { font-size: 36px !important; }
  .lpm_fz38_i { font-size: 38px !important; }
  .lpm_fz40_i { font-size: 40px !important; }
  .lpm_fz42_i { font-size: 42px !important; }
  .lpm_fz44_i { font-size: 44px !important; }
  .lpm_fz46_i { font-size: 46px !important; }
  .lpm_fz48_i { font-size: 48px !important; }
  .lpm_fz50_i { font-size: 50px !important; }

  /* Font weight */
  .lpm_fw900, .lpm_fw900_a, .lpm_fw900_a * { font-family: 'MuseoSansCyrl-900', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 900; }
  .lpm_fw900_i, .lpm_fw900_a_i, .lpm_fw900_a_i * { font-family: 'MuseoSansCyrl-900', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 900 !important; }
  .lpm_fw700, .lpm_fw700_a, .lpm_fw700_a *, .bold, .bold_a, .bold_a *, .semibold, .semibold_a, .semibold_a * { font-family: 'MuseoSansCyrl-700', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 700; }
  .lpm_fw700_i, .lpm_fw700_a_i, .lpm_fw700_a_i *, .bold_i, .bold_a_i, .bold_a_i *, .semibold_i, .semibold_a_i, .semibold_a_i * { font-family: 'MuseoSansCyrl-700', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 700 !important; }
  .lpm_fw400, .lpm_fw400_a, .lpm_fw400_a * { font-family: 'MuseoSansCyrl-500', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 400; }
  .lpm_fw400_i, .lpm_fw400_a_i, .lpm_fw400_a_i * { font-family: 'MuseoSansCyrl-500', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 400 !important; }
  .lpm_fw300, .lpm_fw300_a, .lpm_fw300_a *, .semilight, .semilight_a, .semilight_a * { font-family: 'MuseoSansCyrl-300', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 300; }
  .lpm_fw300_i, .lpm_fw300_a_i, .lpm_fw300_a_i *, .semilight_i, .semilight_a_i, .semilight_a_i * { font-family: 'MuseoSansCyrl-300', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 300 !important; }

  /* Line height */
  .lpm_lh10 { line-height: 1; }
  .lpm_lh100p { line-height: 100%; }
  .lpm_lh11 { line-height: 1.1; }
  .lpm_lh110p { line-height: 110%; }
  .lpm_lh12 { line-height: 1.2; }
  .lpm_lh120p { line-height: 120%; }
  .lpm_lh13 { line-height: 1.3; }
  .lpm_lh130p { line-height: 130%; }
  .lpm_lh14 { line-height: 1.4; }
  .lpm_lh140p { line-height: 140%; }
  .lpm_lh15 { line-height: 1.5; }
  .lpm_lh150p { line-height: 150%; }
  .lpm_lh16 { line-height: 1.6; }
  .lpm_lh160p { line-height: 160%; }
  .lpm_lh17 { line-height: 1.7; }
  .lpm_lh170p { line-height: 170%; }
  .lpm_lh18 { line-height: 1.8; }
  .lpm_lh180p { line-height: 180%; }
  .lpm_lh19 { line-height: 1.9; }
  .lpm_lh190p { line-height: 190%; }
  .lpm_lh20 { line-height: 2; }
  .lpm_lh200p { line-height: 200%; }


  /* Floating */
  /* ======== */
  .lpm_fln { float: none; }
  .lpm_fll { float: left; }
  .lpm_flr { float: right; }
}
@media screen and (max-width: 699px) {
  html {
    font-size: 14px;
  }



  .vitrine-item-image {
    max-width: 200px;
  }

  .vitrine-item-text .subsubheader {
    font-size: 16px;
  }



  .advantages .vitrine-item-image {
    max-width: 150px;
  }



  .price-name {
    padding-left: 20px;
  }
}

@media screen and (max-width: 639px) {
  .gallery .width-6 {
    width: 33.33333333%;
  }
}

@media screen and (max-width: 599px) {
  .gallery .width-4 {
    width: 50%;
  }



  .incut-image {
    flex-direction: column;
    align-items: flex-end;
  }
    .incut-image:after {
      min-width: 180px;
      height: 88px;
      margin: -45px -10px -10px 0px;
      transform: scaleX(-1);
    }

    .incut-image > div,
    .incut > div {
      padding: 18px 30px 20px 20px;
    }



  .price-table-items {
    font-size: 15px;
  }
    .price-table-head {
      font-size: 17px;
    }

    .price-name,
    .price-price {
      padding: 8px 4px;
    }

    .price-name {
      padding-left: 12px;
    }
}

/* portrait mobile - pm */
@media screen and (max-width: 479px) {
  /* Border */
  /* ====== */
  /* Border width */
  .pm_brw0 { border-width: 0px; }
  .pm_brtw0 { border-top-width: 0px; }
  .pm_brlw0 { border-left-width: 0px; }
  .pm_brrw0 { border-right-width: 0px; }
  .pm_brbw0 { border-bottom-width: 0px; }
  .pm_brw1 { border-width: 1px; }
  .pm_brtw1 { border-top-width: 1px; }
  .pm_brlw1 { border-left-width: 1px; }
  .pm_brrw1 { border-right-width: 1px; }
  .pm_brbw1 { border-bottom-width: 1px; }

  /* Border style */
  .pm_brstn { border-style: none; }
  .pm_brsts { border-style: solid; }
  .pm_brstd { border-style: dashed; }


  /* Display */
  /* ======= */
  .pm_dn { display: none; }
  .pm_dn_i { display: none !important; }
  .pm_db { display: block; }
  .pm_di { display: inline; }
  .pm_dib { display: inline-block; }
  .pm_dt { display: table; }
  .pm_dtr { display: table-row; }
  .pm_dtc { display: table-cell; }
  .pm_du { display: unset; }

  /* Flex */
  .pm_dfdr { display: flex; flex-direction: row; }
  .pm_dfdrr { display: flex; flex-direction: row-reverse; }
  .pm_dfdc { display: flex; flex-direction: column; }
  .pm_dfdcr { display: flex; flex-direction: column-reverse; }
  .pm_difdr { display: inline-flex; flex-direction: row; }
  .pm_difdrr { display: inline-flex; flex-direction: row-reverse; }
  .pm_difdc { display: inline-flex; flex-direction: column; }
  .pm_difdcr { display: inline-flex; flex-direction: column-reverse; }
  .pm_fwn { flex-wrap: nowrap; }
  .pm_fww { flex-wrap: wrap; }
  .pm_fwwr { flex-wrap: wrap-reverse; }
  .pm_fjcs { justify-content: flex-start; }
  .pm_fjce { justify-content: flex-end; }
  .pm_fjcc { justify-content: center; }
  .pm_fjcsa { justify-content: space-around; }
  .pm_fjcsb { justify-content: space-between; }
  .pm_fais { align-items: flex-start; }
  .pm_faie { align-items: flex-end; }
  .pm_faic { align-items: center; }
  .pm_faib { align-items: baseline; }
  .pm_faist { align-items: stretch; }
  .pm_facs { align-content: flex-start; }
  .pm_face { align-content: flex-end; }
  .pm_facc { align-content: center; }
  .pm_facsa { align-content: space-around; }
  .pm_facsb { align-content: space-between; }
  .pm_facst { align-content: stretch; }
  .pm_fass { align-self: flex-start; }
  .pm_fase { align-self: flex-end; }
  .pm_fasc { align-self: center; }
  .pm_fasb { align-self: baseline; }
  .pm_fasst { align-self: stretch; }
  .pm_fg0 { flex-grow: 0; }
  .pm_fg1 { flex-grow: 1; }
  .pm_fsh0 { flex-shrink: 0; }
  .pm_fsh1 { flex-shrink: 1; }


  /* Height */
  /* ====== */
  .pm_h0 { height: 0; }
  .pm_h100p, .pm_hfull { height: 100%; }
  .pm_hauto { height: auto; }


  /* Width */
  /* ===== */
  .pm_w0 { width: 0; }
  .pm_w1 { width: 1px; }
  .pm_w5p { width: 5%; }
  .pm_w7p { width: 7%; }
  .pm_w10p { width: 10%; }
  .pm_w12p { width: 12.5%; }
  .pm_w15p { width: 15%; }
  .pm_w20p { width: 20%; }
  .pm_w25p { width: 25%; }
  .pm_w33p { width: 33.33333333%; }
  .pm_w35p { width: 35%; }
  .pm_w40p { width: 40%; }
  .pm_w50p { width: 50%; }
  .pm_w60p { width: 60%; }
  .pm_w65p { width: 65%; }
  .pm_w66p { width: 66.66666666%; }
  .pm_w70p { width: 70%; }
  .pm_w75p { width: 75%; }
  .pm_w80p { width: 80%; }
  .pm_w85p { width: 85%; }
  .pm_w88p { width: 87.5%; }
  .pm_w90p { width: 90%; }
  .pm_w93p { width: 93%; }
  .pm_w95p { width: 95%; }
  .pm_w100p, .pm_wfull { width: 100%; }
  .pm_wauto { width: auto; }


  /* Margins */
  /* ======= */
  .pm_m0auto, .pm_m0a { margin: 0 auto; }
  .pm_m0auto_i, .pm_m0a_i { margin: 0 auto !important; }
  .pm_mauto0, .pm_ma0 { margin: auto 0; }
  .pm_mauto0_i, .pm_ma0_i { margin: auto 0 !important; }
  .pm_mauto, .pm_ma { margin: auto; }
  .pm_mauto_i, .pm_ma_i { margin: auto !important; }
  .pm_mtauto, .pm_mta { margin-top: auto; }
  .pm_mtauto_i, .pm_mta_i { margin-top: auto !important; }
  .pm_mlauto, .pm_mla { margin-left: auto; }
  .pm_mlauto_i, .pm_mla_i { margin-left: auto !important; }
  .pm_mrauto, .pm_mra { margin-right: auto; }
  .pm_mrauto_i, .pm_mra_i { margin-right: auto !important; }
  .pm_mbauto, .pm_mba { margin-bottom: auto; }
  .pm_mbauto_i, .pm_mba_i { margin-bottom: auto !important; }
  .pm_m0 { margin: 0; }
  .pm_m0_i { margin: 0 !important; }
  .pm_mt0 { margin-top: 0; }
  .pm_mt0_i { margin-top: 0 !important; }
  .pm_ml0 { margin-left: 0; }
  .pm_ml0_i { margin-left: 0 !important; }
  .pm_mr0 { margin-right: 0; }
  .pm_mr0_i { margin-right: 0 !important; }
  .pm_mb0 { margin-bottom: 0; }
  .pm_mb0_i { margin-bottom: 0 !important; }
  .pm_m1 { margin: 1px; }
  .pm_mt1 { margin-top: 1px; }
  .pm_ml1 { margin-left: 1px; }
  .pm_mr1 { margin-right: 1px; }
  .pm_mb1 { margin-bottom: 1px; }
  .pm_m2 { margin: 2px; }
  .pm_mt2 { margin-top: 2px; }
  .pm_ml2 { margin-left: 2px; }
  .pm_mr2 { margin-right: 2px; }
  .pm_mb2 { margin-bottom: 2px; }
  .pm_m3 { margin: 3px; }
  .pm_mt3 { margin-top: 3px; }
  .pm_ml3 { margin-left: 3px; }
  .pm_mr3 { margin-right: 3px; }
  .pm_mb3 { margin-bottom: 3px; }
  .pm_m4 { margin: 4px; }
  .pm_mt4 { margin-top: 4px; }
  .pm_ml4 { margin-left: 4px; }
  .pm_mr4 { margin-right: 4px; }
  .pm_mb4 { margin-bottom: 4px; }
  .pm_m5 { margin: 5px; }
  .pm_mt5 { margin-top: 5px; }
  .pm_ml5 { margin-left: 5px; }
  .pm_mr5 { margin-right: 5px; }
  .pm_mb5 { margin-bottom: 5px; }
  .pm_m7 { margin: 7px; }
  .pm_mt7 { margin-top: 7px; }
  .pm_ml7 { margin-left: 7px; }
  .pm_mr7 { margin-right: 7px; }
  .pm_mb7 { margin-bottom: 7px; }
  .pm_m10 { margin: 10px; }
  .pm_mt10 { margin-top: 10px; }
  .pm_ml10 { margin-left: 10px; }
  .pm_mr10 { margin-right: 10px; }
  .pm_mb10 { margin-bottom: 10px; }
  .pm_m13 { margin: 13px; }
  .pm_mt13 { margin-top: 13px; }
  .pm_ml13 { margin-left: 13px; }
  .pm_mr13 { margin-right: 13px; }
  .pm_mb13 { margin-bottom: 13px; }
  .pm_m15 { margin: 15px; }
  .pm_mt15 { margin-top: 15px; }
  .pm_ml15 { margin-left: 15px; }
  .pm_mr15 { margin-right: 15px; }
  .pm_mb15 { margin-bottom: 15px; }
  .pm_m20 { margin: 20px; }
  .pm_mt20 { margin-top: 20px; }
  .pm_ml20 { margin-left: 20px; }
  .pm_mr20 { margin-right: 20px; }
  .pm_mb20 { margin-bottom: 20px; }
  .pm_m25 { margin: 25px; }
  .pm_mt25 { margin-top: 25px; }
  .pm_ml25 { margin-left: 25px; }
  .pm_mr25 { margin-right: 25px; }
  .pm_mb25 { margin-bottom: 25px; }
  .pm_m30 { margin: 30px; }
  .pm_mt30 { margin-top: 30px; }
  .pm_ml30 { margin-left: 30px; }
  .pm_mr30 { margin-right: 30px; }
  .pm_mb30 { margin-bottom: 30px; }
  .pm_m35 { margin: 35px; }
  .pm_mt35 { margin-top: 35px; }
  .pm_ml35 { margin-left: 35px; }
  .pm_mr35 { margin-right: 35px; }
  .pm_mb35 { margin-bottom: 35px; }
  .pm_m-1 { margin: -1px; }
  .pm_mt-1 { margin-top: -1px; }
  .pm_ml-1 { margin-left: -1px; }
  .pm_mr-1 { margin-right: -1px; }
  .pm_mb-1 { margin-bottom: -1px; }
  .pm_m-2 { margin: -2px; }
  .pm_mt-2 { margin-top: -2px; }
  .pm_ml-2 { margin-left: -2px; }
  .pm_mr-2 { margin-right: -2px; }
  .pm_mb-2 { margin-bottom: -2px; }
  .pm_m-3 { margin: -3px; }
  .pm_mt-3 { margin-top: -3px; }
  .pm_ml-3 { margin-left: -3px; }
  .pm_mr-3 { margin-right: -3px; }
  .pm_mb-3 { margin-bottom: -3px; }
  .pm_m-4 { margin: -4px; }
  .pm_mt-4 { margin-top: -4px; }
  .pm_ml-4 { margin-left: -4px; }
  .pm_mr-4 { margin-right: -4px; }
  .pm_mb-4 { margin-bottom: -4px; }
  .pm_m-5 { margin: -5px; }
  .pm_mt-5 { margin-top: -5px; }
  .pm_ml-5 { margin-left: -5px; }
  .pm_mr-5 { margin-right: -5px; }
  .pm_mb-5 { margin-bottom: -5px; }
  .pm_m-7 { margin: -7px; }
  .pm_mt-7 { margin-top: -7px; }
  .pm_ml-7 { margin-left: -7px; }
  .pm_mr-7 { margin-right: -7px; }
  .pm_mb-7 { margin-bottom: -7px; }
  .pm_m-10 { margin: -10px; }
  .pm_mt-10 { margin-top: -10px; }
  .pm_ml-10 { margin-left: -10px; }
  .pm_mr-10 { margin-right: -10px; }
  .pm_mb-10 { margin-bottom: -10px; }
  .pm_m-13 { margin: -13px; }
  .pm_mt-13 { margin-top: -13px; }
  .pm_ml-13 { margin-left: -13px; }
  .pm_mr-13 { margin-right: -13px; }
  .pm_mb-13 { margin-bottom: -13px; }
  .pm_m-15 { margin: -15px; }
  .pm_mt-15 { margin-top: -15px; }
  .pm_ml-15 { margin-left: -15px; }
  .pm_mr-15 { margin-right: -15px; }
  .pm_mb-15 { margin-bottom: -15px; }
  .pm_m-20 { margin: -20px; }
  .pm_mt-20 { margin-top: -20px; }
  .pm_ml-20 { margin-left: -20px; }
  .pm_mr-20 { margin-right: -20px; }
  .pm_mb-20 { margin-bottom: -20px; }
  .pm_m-25 { margin: -25px; }
  .pm_mt-25 { margin-top: -25px; }
  .pm_ml-25 { margin-left: -25px; }
  .pm_mr-25 { margin-right: -25px; }
  .pm_mb-25 { margin-bottom: -25px; }
  .pm_m-30 { margin: -30px; }
  .pm_mt-30 { margin-top: -30px; }
  .pm_ml-30 { margin-left: -30px; }
  .pm_mr-30 { margin-right: -30px; }
  .pm_mb-30 { margin-bottom: -30px; }
  .pm_m-35 { margin: -35px; }
  .pm_mt-35 { margin-top: -35px; }
  .pm_ml-35 { margin-left: -35px; }
  .pm_mr-35 { margin-right: -35px; }
  .pm_mb-35 { margin-bottom: -35px; }


  /* Paddings */
  /* ======== */
  .pm_p0 { padding: 0 !important; }
  .pm_pt0 { padding-top: 0 !important; }
  .pm_pl0 { padding-left: 0 !important; }
  .pm_pr0 { padding-right: 0 !important; }
  .pm_pb0 { padding-bottom: 0 !important; }
  .pm_p1 { padding: 1px !important; }
  .pm_pt1 { padding-top: 1px !important; }
  .pm_pl1 { padding-left: 1px !important; }
  .pm_pr1 { padding-right: 1px !important; }
  .pm_pb1 { padding-bottom: 1px !important; }
  .pm_p2 { padding: 2px !important; }
  .pm_pt2 { padding-top: 2px !important; }
  .pm_pl2 { padding-left: 2px !important; }
  .pm_pr2 { padding-right: 2px !important; }
  .pm_pb2 { padding-bottom: 2px !important; }
  .pm_p3 { padding: 3px !important; }
  .pm_pt3 { padding-top: 3px !important; }
  .pm_pl3 { padding-left: 3px !important; }
  .pm_pr3 { padding-right: 3px !important; }
  .pm_pb3 { padding-bottom: 3px !important; }
  .pm_p4 { padding: 4px !important; }
  .pm_pt4 { padding-top: 4px !important; }
  .pm_pl4 { padding-left: 4px !important; }
  .pm_pr4 { padding-right: 4px !important; }
  .pm_pb4 { padding-bottom: 4px !important; }
  .pm_p5 { padding: 5px !important; }
  .pm_pt5 { padding-top: 5px !important; }
  .pm_pl5 { padding-left: 5px !important; }
  .pm_pr5 { padding-right: 5px !important; }
  .pm_pb5 { padding-bottom: 5px !important; }
  .pm_p7 { padding: 7px !important; }
  .pm_pt7 { padding-top: 7px !important; }
  .pm_pl7 { padding-left: 7px !important; }
  .pm_pr7 { padding-right: 7px !important; }
  .pm_pb7 { padding-bottom: 7px !important; }
  .pm_p10 { padding: 10px !important; }
  .pm_pt10 { padding-top: 10px !important; }
  .pm_pl10 { padding-left: 10px !important; }
  .pm_pr10 { padding-right: 10px !important; }
  .pm_pb10 { padding-bottom: 10px !important; }
  .pm_p13 { padding: 13px !important; }
  .pm_pt13 { padding-top: 13px !important; }
  .pm_pl13 { padding-left: 13px !important; }
  .pm_pr13 { padding-right: 13px !important; }
  .pm_pb13 { padding-bottom: 13px !important; }
  .pm_p15 { padding: 15px !important; }
  .pm_pt15 { padding-top: 15px !important; }
  .pm_pl15 { padding-left: 15px !important; }
  .pm_pr15 { padding-right: 15px !important; }
  .pm_pb15 { padding-bottom: 15px !important; }
  .pm_p20 { padding: 20px !important; }
  .pm_pt20 { padding-top: 20px !important; }
  .pm_pl20 { padding-left: 20px !important; }
  .pm_pr20 { padding-right: 20px !important; }
  .pm_pb20 { padding-bottom: 20px !important; }
  .pm_p25 { padding: 25px !important; }
  .pm_pt25 { padding-top: 25px !important; }
  .pm_pl25 { padding-left: 25px !important; }
  .pm_pr25 { padding-right: 25px !important; }
  .pm_pb25 { padding-bottom: 25px !important; }
  .pm_p30 { padding: 30px !important; }
  .pm_pt30 { padding-top: 30px !important; }
  .pm_pl30 { padding-left: 30px !important; }
  .pm_pr30 { padding-right: 30px !important; }
  .pm_pb30 { padding-bottom: 30px !important; }
  .pm_p35 { padding: 35px !important; }
  .pm_pt35 { padding-top: 35px !important; }
  .pm_pl35 { padding-left: 35px !important; }
  .pm_pr35 { padding-right: 35px !important; }
  .pm_pb35 { padding-bottom: 35px !important; }

  .pm_pt10p { padding-top: 10% !important; }
  .pm_pt15p { padding-top: 15% !important; }
  .pm_pt20p { padding-top: 20% !important; }
  .pm_pt25p { padding-top: 25% !important; }
  .pm_pt33p { padding-top: 33.33333333% !important; }
  .pm_pt35p { padding-top: 35% !important; }
  .pm_pt40p { padding-top: 40% !important; }
  .pm_pt50p { padding-top: 50% !important; }
  .pm_pt60p { padding-top: 60% !important; }
  .pm_pt65p { padding-top: 65% !important; }
  .pm_pt66p { padding-top: 66.66666666% !important; }
  .pm_pt70p { padding-top: 70% !important; }
  .pm_pt75p { padding-top: 75% !important; }
  .pm_pt80p { padding-top: 80% !important; }
  .pm_pt85p { padding-top: 85% !important; }
  .pm_pt90p { padding-top: 90% !important; }
  .pm_pt100p { padding-top: 100% !important; }


  /* Aligning */
  /* ======== */
  /* Vertical align */
  .pm_vat { vertical-align: top; }
  .pm_vam { vertical-align: middle; }
  .pm_vab { vertical-align: bottom; }
  .pm_vabl { vertical-align: baseline; }

  /* Horizontal text align */
  .pm_tal { text-align: left; }
  .pm_tal_i { text-align: left !important; }
  .pm_tac { text-align: center; }
  .pm_tac_i { text-align: center !important; }
  .pm_tar { text-align: right; }
  .pm_tar_i { text-align: right !important; }
  .pm_taj { text-align: justify; }
  .pm_taj_i { text-align: justify !important; }


  /* Text styling */
  /* ============ */
  /* Font style*/
  .pm_fsi { font-style: italic; }

  /* Font size */
  .pm_fz0 { font-size: 0; }
  .pm_fz10, .pm_fz10_a, .pm_fz10_a * { font-size: 10px; }
  .pm_fz11, .pm_fz11_a, .pm_fz11_a * { font-size: 11px; }
  .pm_fz12, .pm_fz12_a, .pm_fz12_a * { font-size: 12px; }
  .pm_fz13, .pm_fz13_a, .pm_fz13_a * { font-size: 13px; }
  .pm_fz14, .pm_fz14_a, .pm_fz14_a * { font-size: 14px; }
  .pm_fz15 { font-size: 15px; }
  .pm_fz16 { font-size: 16px; }
  .pm_fz18 { font-size: 18px; }
  .pm_fz20 { font-size: 20px; }
  .pm_fz22 { font-size: 22px; }
  .pm_fz24 { font-size: 24px; }
  .pm_fz26 { font-size: 26px; }
  .pm_fz28 { font-size: 28px; }
  .pm_fz30 { font-size: 30px; }
  .pm_fz32 { font-size: 32px; }
  .pm_fz34 { font-size: 34px; }
  .pm_fz36 { font-size: 36px; }
  .pm_fz38 { font-size: 38px; }
  .pm_fz40 { font-size: 40px; }
  .pm_fz42 { font-size: 42px; }
  .pm_fz44 { font-size: 44px; }
  .pm_fz46 { font-size: 46px; }
  .pm_fz48 { font-size: 48px; }
  .pm_fz50 { font-size: 50px; }
  .pm_fz0_i { font-size: 0 !important; }
  .pm_fz10_i, .pm_fz10_a_i, .pm_fz10_a_i * { font-size: 10px !important; }
  .pm_fz11_i, .pm_fz11_a_i, .pm_fz11_a_i * { font-size: 11px !important; }
  .pm_fz12_i, .pm_fz12_a_i, .pm_fz12_a_i * { font-size: 12px !important; }
  .pm_fz13_i, .pm_fz13_a_i, .pm_fz13_a_i * { font-size: 13px !important; }
  .pm_fz14_i, .pm_fz14_a_i, .pm_fz14_a_i * { font-size: 14px !important; }
  .pm_fz15_i { font-size: 15px !important; }
  .pm_fz16_i { font-size: 16px !important; }
  .pm_fz18_i { font-size: 18px !important; }
  .pm_fz20_i { font-size: 20px !important; }
  .pm_fz22_i { font-size: 22px !important; }
  .pm_fz24_i { font-size: 24px !important; }
  .pm_fz26_i { font-size: 26px !important; }
  .pm_fz28_i { font-size: 28px !important; }
  .pm_fz30_i { font-size: 30px !important; }
  .pm_fz32_i { font-size: 32px !important; }
  .pm_fz34_i { font-size: 34px !important; }
  .pm_fz36_i { font-size: 36px !important; }
  .pm_fz38_i { font-size: 38px !important; }
  .pm_fz40_i { font-size: 40px !important; }
  .pm_fz42_i { font-size: 42px !important; }
  .pm_fz44_i { font-size: 44px !important; }
  .pm_fz46_i { font-size: 46px !important; }
  .pm_fz48_i { font-size: 48px !important; }
  .pm_fz50_i { font-size: 50px !important; }

  /* Font weight */
  .pm_fw900, .pm_fw900_a, .pm_fw900_a * { font-family: 'MuseoSansCyrl-900', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 900; }
  .pm_fw900_i, .pm_fw900_a_i, .pm_fw900_a_i * { font-family: 'MuseoSansCyrl-900', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 900 !important; }
  .pm_fw700, .pm_fw700_a, .pm_fw700_a *, .bold, .bold_a, .bold_a *, .semibold, .semibold_a, .semibold_a * { font-family: 'MuseoSansCyrl-700', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 700; }
  .pm_fw700_i, .pm_fw700_a_i, .pm_fw700_a_i *, .bold_i, .bold_a_i, .bold_a_i *, .semibold_i, .semibold_a_i, .semibold_a_i * { font-family: 'MuseoSansCyrl-700', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 700 !important; }
  .pm_fw400, .pm_fw400_a, .pm_fw400_a * { font-family: 'MuseoSansCyrl-500', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 400; }
  .pm_fw400_i, .pm_fw400_a_i, .pm_fw400_a_i * { font-family: 'MuseoSansCyrl-500', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 400 !important; }
  .pm_fw300, .pm_fw300_a, .pm_fw300_a *, .semilight, .semilight_a, .semilight_a * { font-family: 'MuseoSansCyrl-300', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 300; }
  .pm_fw300_i, .pm_fw300_a_i, .pm_fw300_a_i *, .semilight_i, .semilight_a_i, .semilight_a_i * { font-family: 'MuseoSansCyrl-300', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 300 !important; }

  /* Line height */
  .pm_lh10 { line-height: 1; }
  .pm_lh100p { line-height: 100%; }
  .pm_lh11 { line-height: 1.1; }
  .pm_lh110p { line-height: 110%; }
  .pm_lh12 { line-height: 1.2; }
  .pm_lh120p { line-height: 120%; }
  .pm_lh13 { line-height: 1.3; }
  .pm_lh130p { line-height: 130%; }
  .pm_lh14 { line-height: 1.4; }
  .pm_lh140p { line-height: 140%; }
  .pm_lh15 { line-height: 1.5; }
  .pm_lh150p { line-height: 150%; }
  .pm_lh16 { line-height: 1.6; }
  .pm_lh160p { line-height: 160%; }
  .pm_lh17 { line-height: 1.7; }
  .pm_lh170p { line-height: 170%; }
  .pm_lh18 { line-height: 1.8; }
  .pm_lh180p { line-height: 180%; }
  .pm_lh19 { line-height: 1.9; }
  .pm_lh190p { line-height: 190%; }
  .pm_lh20 { line-height: 2; }
  .pm_lh200p { line-height: 200%; }


  /* Floating */
  /* ======== */
  .pm_fln { float: none; }
  .pm_fll { float: left; }
  .pm_flr { float: right; }
}
@media screen and (max-width: 479px) {
  .form-popup-container {
    width: 100%;
  }
    .form-popup-container .form-block-container {
      min-width: unset;
    }



  .header-menu-button-wrapper + div {
    width: 100%;
    min-width: unset;
  }
}

/* small mobile - sm */
@media screen and (max-width: 359px) {
  /* Border */
  /* ====== */
  /* Border width */
  .sm_brw0 { border-width: 0px; }
  .sm_brtw0 { border-top-width: 0px; }
  .sm_brlw0 { border-left-width: 0px; }
  .sm_brrw0 { border-right-width: 0px; }
  .sm_brbw0 { border-bottom-width: 0px; }
  .sm_brw1 { border-width: 1px; }
  .sm_brtw1 { border-top-width: 1px; }
  .sm_brlw1 { border-left-width: 1px; }
  .sm_brrw1 { border-right-width: 1px; }
  .sm_brbw1 { border-bottom-width: 1px; }

  /* Border style */
  .sm_brstn { border-style: none; }
  .sm_brsts { border-style: solid; }
  .sm_brstd { border-style: dashed; }


  /* Display */
  /* ======= */
  .sm_dn { display: none; }
  .sm_dn_i { display: none !important; }
  .sm_db { display: block; }
  .sm_di { display: inline; }
  .sm_dib { display: inline-block; }
  .sm_dt { display: table; }
  .sm_dtr { display: table-row; }
  .sm_dtc { display: table-cell; }
  .sm_du { display: unset; }

  /* Flex */
  .sm_dfdr { display: flex; flex-direction: row; }
  .sm_dfdrr { display: flex; flex-direction: row-reverse; }
  .sm_dfdc { display: flex; flex-direction: column; }
  .sm_dfdcr { display: flex; flex-direction: column-reverse; }
  .sm_difdr { display: inline-flex; flex-direction: row; }
  .sm_difdrr { display: inline-flex; flex-direction: row-reverse; }
  .sm_difdc { display: inline-flex; flex-direction: column; }
  .sm_difdcr { display: inline-flex; flex-direction: column-reverse; }
  .sm_fwn { flex-wrap: nowrap; }
  .sm_fww { flex-wrap: wrap; }
  .sm_fwwr { flex-wrap: wrap-reverse; }
  .sm_fjcs { justify-content: flex-start; }
  .sm_fjce { justify-content: flex-end; }
  .sm_fjcc { justify-content: center; }
  .sm_fjcsa { justify-content: space-around; }
  .sm_fjcsb { justify-content: space-between; }
  .sm_fais { align-items: flex-start; }
  .sm_faie { align-items: flex-end; }
  .sm_faic { align-items: center; }
  .sm_faib { align-items: baseline; }
  .sm_faist { align-items: stretch; }
  .sm_facs { align-content: flex-start; }
  .sm_face { align-content: flex-end; }
  .sm_facc { align-content: center; }
  .sm_facsa { align-content: space-around; }
  .sm_facsb { align-content: space-between; }
  .sm_facst { align-content: stretch; }
  .sm_fass { align-self: flex-start; }
  .sm_fase { align-self: flex-end; }
  .sm_fasc { align-self: center; }
  .sm_fasb { align-self: baseline; }
  .sm_fasst { align-self: stretch; }
  .sm_fg0 { flex-grow: 0; }
  .sm_fg1 { flex-grow: 1; }
  .sm_fsh0 { flex-shrink: 0; }
  .sm_fsh1 { flex-shrink: 1; }


  /* Height */
  /* ====== */
  .sm_h0 { height: 0; }
  .sm_h100p, .sm_hfull { height: 100%; }
  .sm_hauto { height: auto; }


  /* Width */
  /* ===== */
  .sm_w0 { width: 0; }
  .sm_w1 { width: 1px; }
  .sm_w5p { width: 5%; }
  .sm_w7p { width: 7%; }
  .sm_w10p { width: 10%; }
  .sm_w12p { width: 12.5%; }
  .sm_w15p { width: 15%; }
  .sm_w20p { width: 20%; }
  .sm_w25p { width: 25%; }
  .sm_w33p { width: 33.33333333%; }
  .sm_w35p { width: 35%; }
  .sm_w40p { width: 40%; }
  .sm_w50p { width: 50%; }
  .sm_w60p { width: 60%; }
  .sm_w65p { width: 65%; }
  .sm_w66p { width: 66.66666666%; }
  .sm_w70p { width: 70%; }
  .sm_w75p { width: 75%; }
  .sm_w80p { width: 80%; }
  .sm_w85p { width: 85%; }
  .sm_w88p { width: 87.5%; }
  .sm_w90p { width: 90%; }
  .sm_w93p { width: 93%; }
  .sm_w95p { width: 95%; }
  .sm_w100p, .sm_wfull { width: 100%; }
  .sm_wauto { width: auto; }


  /* Margins */
  /* ======= */
  .sm_m0auto, .sm_m0a { margin: 0 auto; }
  .sm_m0auto_i, .sm_m0a_i { margin: 0 auto !important; }
  .sm_mauto0, .sm_ma0 { margin: auto 0; }
  .sm_mauto0_i, .sm_ma0_i { margin: auto 0 !important; }
  .sm_mauto, .sm_ma { margin: auto; }
  .sm_mauto_i, .sm_ma_i { margin: auto !important; }
  .sm_mtauto, .sm_mta { margin-top: auto; }
  .sm_mtauto_i, .sm_mta_i { margin-top: auto !important; }
  .sm_mlauto, .sm_mla { margin-left: auto; }
  .sm_mlauto_i, .sm_mla_i { margin-left: auto !important; }
  .sm_mrauto, .sm_mra { margin-right: auto; }
  .sm_mrauto_i, .sm_mra_i { margin-right: auto !important; }
  .sm_mbauto, .sm_mba { margin-bottom: auto; }
  .sm_mbauto_i, .sm_mba_i { margin-bottom: auto !important; }
  .sm_m0 { margin: 0; }
  .sm_m0_i { margin: 0 !important; }
  .sm_mt0 { margin-top: 0; }
  .sm_mt0_i { margin-top: 0 !important; }
  .sm_ml0 { margin-left: 0; }
  .sm_ml0_i { margin-left: 0 !important; }
  .sm_mr0 { margin-right: 0; }
  .sm_mr0_i { margin-right: 0 !important; }
  .sm_mb0 { margin-bottom: 0; }
  .sm_mb0_i { margin-bottom: 0 !important; }
  .sm_m1 { margin: 1px; }
  .sm_mt1 { margin-top: 1px; }
  .sm_ml1 { margin-left: 1px; }
  .sm_mr1 { margin-right: 1px; }
  .sm_mb1 { margin-bottom: 1px; }
  .sm_m2 { margin: 2px; }
  .sm_mt2 { margin-top: 2px; }
  .sm_ml2 { margin-left: 2px; }
  .sm_mr2 { margin-right: 2px; }
  .sm_mb2 { margin-bottom: 2px; }
  .sm_m3 { margin: 3px; }
  .sm_mt3 { margin-top: 3px; }
  .sm_ml3 { margin-left: 3px; }
  .sm_mr3 { margin-right: 3px; }
  .sm_mb3 { margin-bottom: 3px; }
  .sm_m4 { margin: 4px; }
  .sm_mt4 { margin-top: 4px; }
  .sm_ml4 { margin-left: 4px; }
  .sm_mr4 { margin-right: 4px; }
  .sm_mb4 { margin-bottom: 4px; }
  .sm_m5 { margin: 5px; }
  .sm_mt5 { margin-top: 5px; }
  .sm_ml5 { margin-left: 5px; }
  .sm_mr5 { margin-right: 5px; }
  .sm_mb5 { margin-bottom: 5px; }
  .sm_m7 { margin: 7px; }
  .sm_mt7 { margin-top: 7px; }
  .sm_ml7 { margin-left: 7px; }
  .sm_mr7 { margin-right: 7px; }
  .sm_mb7 { margin-bottom: 7px; }
  .sm_m10 { margin: 10px; }
  .sm_mt10 { margin-top: 10px; }
  .sm_ml10 { margin-left: 10px; }
  .sm_mr10 { margin-right: 10px; }
  .sm_mb10 { margin-bottom: 10px; }
  .sm_m13 { margin: 13px; }
  .sm_mt13 { margin-top: 13px; }
  .sm_ml13 { margin-left: 13px; }
  .sm_mr13 { margin-right: 13px; }
  .sm_mb13 { margin-bottom: 13px; }
  .sm_m15 { margin: 15px; }
  .sm_mt15 { margin-top: 15px; }
  .sm_ml15 { margin-left: 15px; }
  .sm_mr15 { margin-right: 15px; }
  .sm_mb15 { margin-bottom: 15px; }
  .sm_m20 { margin: 20px; }
  .sm_mt20 { margin-top: 20px; }
  .sm_ml20 { margin-left: 20px; }
  .sm_mr20 { margin-right: 20px; }
  .sm_mb20 { margin-bottom: 20px; }
  .sm_m25 { margin: 25px; }
  .sm_mt25 { margin-top: 25px; }
  .sm_ml25 { margin-left: 25px; }
  .sm_mr25 { margin-right: 25px; }
  .sm_mb25 { margin-bottom: 25px; }
  .sm_m30 { margin: 30px; }
  .sm_mt30 { margin-top: 30px; }
  .sm_ml30 { margin-left: 30px; }
  .sm_mr30 { margin-right: 30px; }
  .sm_mb30 { margin-bottom: 30px; }
  .sm_m35 { margin: 35px; }
  .sm_mt35 { margin-top: 35px; }
  .sm_ml35 { margin-left: 35px; }
  .sm_mr35 { margin-right: 35px; }
  .sm_mb35 { margin-bottom: 35px; }
  .sm_m-1 { margin: -1px; }
  .sm_mt-1 { margin-top: -1px; }
  .sm_ml-1 { margin-left: -1px; }
  .sm_mr-1 { margin-right: -1px; }
  .sm_mb-1 { margin-bottom: -1px; }
  .sm_m-2 { margin: -2px; }
  .sm_mt-2 { margin-top: -2px; }
  .sm_ml-2 { margin-left: -2px; }
  .sm_mr-2 { margin-right: -2px; }
  .sm_mb-2 { margin-bottom: -2px; }
  .sm_m-3 { margin: -3px; }
  .sm_mt-3 { margin-top: -3px; }
  .sm_ml-3 { margin-left: -3px; }
  .sm_mr-3 { margin-right: -3px; }
  .sm_mb-3 { margin-bottom: -3px; }
  .sm_m-4 { margin: -4px; }
  .sm_mt-4 { margin-top: -4px; }
  .sm_ml-4 { margin-left: -4px; }
  .sm_mr-4 { margin-right: -4px; }
  .sm_mb-4 { margin-bottom: -4px; }
  .sm_m-5 { margin: -5px; }
  .sm_mt-5 { margin-top: -5px; }
  .sm_ml-5 { margin-left: -5px; }
  .sm_mr-5 { margin-right: -5px; }
  .sm_mb-5 { margin-bottom: -5px; }
  .sm_m-7 { margin: -7px; }
  .sm_mt-7 { margin-top: -7px; }
  .sm_ml-7 { margin-left: -7px; }
  .sm_mr-7 { margin-right: -7px; }
  .sm_mb-7 { margin-bottom: -7px; }
  .sm_m-10 { margin: -10px; }
  .sm_mt-10 { margin-top: -10px; }
  .sm_ml-10 { margin-left: -10px; }
  .sm_mr-10 { margin-right: -10px; }
  .sm_mb-10 { margin-bottom: -10px; }
  .sm_m-13 { margin: -13px; }
  .sm_mt-13 { margin-top: -13px; }
  .sm_ml-13 { margin-left: -13px; }
  .sm_mr-13 { margin-right: -13px; }
  .sm_mb-13 { margin-bottom: -13px; }
  .sm_m-15 { margin: -15px; }
  .sm_mt-15 { margin-top: -15px; }
  .sm_ml-15 { margin-left: -15px; }
  .sm_mr-15 { margin-right: -15px; }
  .sm_mb-15 { margin-bottom: -15px; }
  .sm_m-20 { margin: -20px; }
  .sm_mt-20 { margin-top: -20px; }
  .sm_ml-20 { margin-left: -20px; }
  .sm_mr-20 { margin-right: -20px; }
  .sm_mb-20 { margin-bottom: -20px; }
  .sm_m-25 { margin: -25px; }
  .sm_mt-25 { margin-top: -25px; }
  .sm_ml-25 { margin-left: -25px; }
  .sm_mr-25 { margin-right: -25px; }
  .sm_mb-25 { margin-bottom: -25px; }
  .sm_m-30 { margin: -30px; }
  .sm_mt-30 { margin-top: -30px; }
  .sm_ml-30 { margin-left: -30px; }
  .sm_mr-30 { margin-right: -30px; }
  .sm_mb-30 { margin-bottom: -30px; }
  .sm_m-35 { margin: -35px; }
  .sm_mt-35 { margin-top: -35px; }
  .sm_ml-35 { margin-left: -35px; }
  .sm_mr-35 { margin-right: -35px; }
  .sm_mb-35 { margin-bottom: -35px; }


  /* Paddings */
  /* ======== */
  .sm_p0 { padding: 0 !important; }
  .sm_pt0 { padding-top: 0 !important; }
  .sm_pl0 { padding-left: 0 !important; }
  .sm_pr0 { padding-right: 0 !important; }
  .sm_pb0 { padding-bottom: 0 !important; }
  .sm_p1 { padding: 1px !important; }
  .sm_pt1 { padding-top: 1px !important; }
  .sm_pl1 { padding-left: 1px !important; }
  .sm_pr1 { padding-right: 1px !important; }
  .sm_pb1 { padding-bottom: 1px !important; }
  .sm_p2 { padding: 2px !important; }
  .sm_pt2 { padding-top: 2px !important; }
  .sm_pl2 { padding-left: 2px !important; }
  .sm_pr2 { padding-right: 2px !important; }
  .sm_pb2 { padding-bottom: 2px !important; }
  .sm_p3 { padding: 3px !important; }
  .sm_pt3 { padding-top: 3px !important; }
  .sm_pl3 { padding-left: 3px !important; }
  .sm_pr3 { padding-right: 3px !important; }
  .sm_pb3 { padding-bottom: 3px !important; }
  .sm_p4 { padding: 4px !important; }
  .sm_pt4 { padding-top: 4px !important; }
  .sm_pl4 { padding-left: 4px !important; }
  .sm_pr4 { padding-right: 4px !important; }
  .sm_pb4 { padding-bottom: 4px !important; }
  .sm_p5 { padding: 5px !important; }
  .sm_pt5 { padding-top: 5px !important; }
  .sm_pl5 { padding-left: 5px !important; }
  .sm_pr5 { padding-right: 5px !important; }
  .sm_pb5 { padding-bottom: 5px !important; }
  .sm_p7 { padding: 7px !important; }
  .sm_pt7 { padding-top: 7px !important; }
  .sm_pl7 { padding-left: 7px !important; }
  .sm_pr7 { padding-right: 7px !important; }
  .sm_pb7 { padding-bottom: 7px !important; }
  .sm_p10 { padding: 10px !important; }
  .sm_pt10 { padding-top: 10px !important; }
  .sm_pl10 { padding-left: 10px !important; }
  .sm_pr10 { padding-right: 10px !important; }
  .sm_pb10 { padding-bottom: 10px !important; }
  .sm_p13 { padding: 13px !important; }
  .sm_pt13 { padding-top: 13px !important; }
  .sm_pl13 { padding-left: 13px !important; }
  .sm_pr13 { padding-right: 13px !important; }
  .sm_pb13 { padding-bottom: 13px !important; }
  .sm_p15 { padding: 15px !important; }
  .sm_pt15 { padding-top: 15px !important; }
  .sm_pl15 { padding-left: 15px !important; }
  .sm_pr15 { padding-right: 15px !important; }
  .sm_pb15 { padding-bottom: 15px !important; }
  .sm_p20 { padding: 20px !important; }
  .sm_pt20 { padding-top: 20px !important; }
  .sm_pl20 { padding-left: 20px !important; }
  .sm_pr20 { padding-right: 20px !important; }
  .sm_pb20 { padding-bottom: 20px !important; }
  .sm_p25 { padding: 25px !important; }
  .sm_pt25 { padding-top: 25px !important; }
  .sm_pl25 { padding-left: 25px !important; }
  .sm_pr25 { padding-right: 25px !important; }
  .sm_pb25 { padding-bottom: 25px !important; }
  .sm_p30 { padding: 30px !important; }
  .sm_pt30 { padding-top: 30px !important; }
  .sm_pl30 { padding-left: 30px !important; }
  .sm_pr30 { padding-right: 30px !important; }
  .sm_pb30 { padding-bottom: 30px !important; }
  .sm_p35 { padding: 35px !important; }
  .sm_pt35 { padding-top: 35px !important; }
  .sm_pl35 { padding-left: 35px !important; }
  .sm_pr35 { padding-right: 35px !important; }
  .sm_pb35 { padding-bottom: 35px !important; }

  .sm_pt10p { padding-top: 10% !important; }
  .sm_pt15p { padding-top: 15% !important; }
  .sm_pt20p { padding-top: 20% !important; }
  .sm_pt25p { padding-top: 25% !important; }
  .sm_pt33p { padding-top: 33.33333333% !important; }
  .sm_pt35p { padding-top: 35% !important; }
  .sm_pt40p { padding-top: 40% !important; }
  .sm_pt50p { padding-top: 50% !important; }
  .sm_pt60p { padding-top: 60% !important; }
  .sm_pt65p { padding-top: 65% !important; }
  .sm_pt66p { padding-top: 66.66666666% !important; }
  .sm_pt70p { padding-top: 70% !important; }
  .sm_pt75p { padding-top: 75% !important; }
  .sm_pt80p { padding-top: 80% !important; }
  .sm_pt85p { padding-top: 85% !important; }
  .sm_pt90p { padding-top: 90% !important; }
  .sm_pt100p { padding-top: 100% !important; }


  /* Aligning */
  /* ======== */
  /* Vertical align */
  .sm_vat { vertical-align: top; }
  .sm_vam { vertical-align: middle; }
  .sm_vab { vertical-align: bottom; }
  .sm_vabl { vertical-align: baseline; }

  /* Horizontal text align */
  .sm_tal { text-align: left; }
  .sm_tal_i { text-align: left !important; }
  .sm_tac { text-align: center; }
  .sm_tac_i { text-align: center !important; }
  .sm_tar { text-align: right; }
  .sm_tar_i { text-align: right !important; }
  .sm_taj { text-align: justify; }
  .sm_taj_i { text-align: justify !important; }


  /* Text styling */
  /* ============ */
  /* Font style*/
  .sm_fsi { font-style: italic; }

  /* Font size */
  .sm_fz0 { font-size: 0; }
  .sm_fz10, .sm_fz10_a, .sm_fz10_a * { font-size: 10px; }
  .sm_fz11, .sm_fz11_a, .sm_fz11_a * { font-size: 11px; }
  .sm_fz12, .sm_fz12_a, .sm_fz12_a * { font-size: 12px; }
  .sm_fz13, .sm_fz13_a, .sm_fz13_a * { font-size: 13px; }
  .sm_fz14, .sm_fz14_a, .sm_fz14_a * { font-size: 14px; }
  .sm_fz15 { font-size: 15px; }
  .sm_fz16 { font-size: 16px; }
  .sm_fz18 { font-size: 18px; }
  .sm_fz20 { font-size: 20px; }
  .sm_fz22 { font-size: 22px; }
  .sm_fz24 { font-size: 24px; }
  .sm_fz26 { font-size: 26px; }
  .sm_fz28 { font-size: 28px; }
  .sm_fz30 { font-size: 30px; }
  .sm_fz32 { font-size: 32px; }
  .sm_fz34 { font-size: 34px; }
  .sm_fz36 { font-size: 36px; }
  .sm_fz38 { font-size: 38px; }
  .sm_fz40 { font-size: 40px; }
  .sm_fz42 { font-size: 42px; }
  .sm_fz44 { font-size: 44px; }
  .sm_fz46 { font-size: 46px; }
  .sm_fz48 { font-size: 48px; }
  .sm_fz50 { font-size: 50px; }
  .sm_fz0_i { font-size: 0 !important; }
  .sm_fz10_i, .sm_fz10_a_i, .sm_fz10_a_i * { font-size: 10px !important; }
  .sm_fz11_i, .sm_fz11_a_i, .sm_fz11_a_i * { font-size: 11px !important; }
  .sm_fz12_i, .sm_fz12_a_i, .sm_fz12_a_i * { font-size: 12px !important; }
  .sm_fz13_i, .sm_fz13_a_i, .sm_fz13_a_i * { font-size: 13px !important; }
  .sm_fz14_i, .sm_fz14_a_i, .sm_fz14_a_i * { font-size: 14px !important; }
  .sm_fz15_i { font-size: 15px !important; }
  .sm_fz16_i { font-size: 16px !important; }
  .sm_fz18_i { font-size: 18px !important; }
  .sm_fz20_i { font-size: 20px !important; }
  .sm_fz22_i { font-size: 22px !important; }
  .sm_fz24_i { font-size: 24px !important; }
  .sm_fz26_i { font-size: 26px !important; }
  .sm_fz28_i { font-size: 28px !important; }
  .sm_fz30_i { font-size: 30px !important; }
  .sm_fz32_i { font-size: 32px !important; }
  .sm_fz34_i { font-size: 34px !important; }
  .sm_fz36_i { font-size: 36px !important; }
  .sm_fz38_i { font-size: 38px !important; }
  .sm_fz40_i { font-size: 40px !important; }
  .sm_fz42_i { font-size: 42px !important; }
  .sm_fz44_i { font-size: 44px !important; }
  .sm_fz46_i { font-size: 46px !important; }
  .sm_fz48_i { font-size: 48px !important; }
  .sm_fz50_i { font-size: 50px !important; }

  /* Font weight */
  .sm_fw900, .sm_fw900_a, .sm_fw900_a * { font-family: 'MuseoSansCyrl-900', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 900; }
  .sm_fw900_i, .sm_fw900_a_i, .sm_fw900_a_i * { font-family: 'MuseoSansCyrl-900', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 900 !important; }
  .sm_fw700, .sm_fw700_a, .sm_fw700_a *, .bold, .bold_a, .bold_a *, .semibold, .semibold_a, .semibold_a * { font-family: 'MuseoSansCyrl-700', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 700; }
  .sm_fw700_i, .sm_fw700_a_i, .sm_fw700_a_i *, .bold_i, .bold_a_i, .bold_a_i *, .semibold_i, .semibold_a_i, .semibold_a_i * { font-family: 'MuseoSansCyrl-700', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 700 !important; }
  .sm_fw400, .sm_fw400_a, .sm_fw400_a * { font-family: 'MuseoSansCyrl-500', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 400; }
  .sm_fw400_i, .sm_fw400_a_i, .sm_fw400_a_i * { font-family: 'MuseoSansCyrl-500', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 400 !important; }
  .sm_fw300, .sm_fw300_a, .sm_fw300_a *, .semilight, .semilight_a, .semilight_a * { font-family: 'MuseoSansCyrl-300', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 300; }
  .sm_fw300_i, .sm_fw300_a_i, .sm_fw300_a_i *, .semilight_i, .semilight_a_i, .semilight_a_i * { font-family: 'MuseoSansCyrl-300', 'Helvetica', 'Tahoma', 'Arial', sans-serif; font-weight: 300 !important; }

  /* Line height */
  .sm_lh10 { line-height: 1; }
  .sm_lh100p { line-height: 100%; }
  .sm_lh11 { line-height: 1.1; }
  .sm_lh110p { line-height: 110%; }
  .sm_lh12 { line-height: 1.2; }
  .sm_lh120p { line-height: 120%; }
  .sm_lh13 { line-height: 1.3; }
  .sm_lh130p { line-height: 130%; }
  .sm_lh14 { line-height: 1.4; }
  .sm_lh140p { line-height: 140%; }
  .sm_lh15 { line-height: 1.5; }
  .sm_lh150p { line-height: 150%; }
  .sm_lh16 { line-height: 1.6; }
  .sm_lh160p { line-height: 160%; }
  .sm_lh17 { line-height: 1.7; }
  .sm_lh170p { line-height: 170%; }
  .sm_lh18 { line-height: 1.8; }
  .sm_lh180p { line-height: 180%; }
  .sm_lh19 { line-height: 1.9; }
  .sm_lh190p { line-height: 190%; }
  .sm_lh20 { line-height: 2; }
  .sm_lh200p { line-height: 200%; }


  /* Floating */
  /* ======== */
  .sm_fln { float: none; }
  .sm_fll { float: left; }
  .sm_flr { float: right; }
}
@media screen and (max-width: 359px) {
}