#uniloader-mouse {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000001;
  background: url('/i/ajax/ajax.gif') no-repeat 0 0;
  background-size: contain;
  width: 32px;
  height: 32px;
}